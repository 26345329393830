import $ from "jquery";

export class BookReadMore {
  constructor($el) {
    this.$el = $el;
    this.$toggle = $el.find(".js-book-details__read-more");
    this.descriptionText = this.$el.find(".js-book-details__description__text");
    const descriptionLength = $(this.descriptionText).text().length;
    this.initialHeight =  this.descriptionText.height() + 40; //some padding :)
    this.bindEvents();
    if (descriptionLength > 250) {
      this.$toggle.css({ "visibility": "visible", "margin-top": "40px" });
      $(this.descriptionText).css({"height": "220px", "overflow": "hidden"});
    }
  }

  bindEvents() {
    this.$toggle.click(event => this.toggleMenu(event));
  }

  toggleMenu(event) {
    event.preventDefault();
    if(this.$el.hasClass("is-open")){
      $(this.descriptionText).css({"height": "220px", "overflow": "hidden"});
    }else {
      $(this.descriptionText).css({"height": this.initialHeight});

    }
    this.$el.toggleClass("is-open");

  }
}
