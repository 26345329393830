import React from "react";
import LoadingSpinner from "./LoadingSpinner.jsx";
import SearchDropdown from "../search dropdown/SearchDropdown.json"

export default class SearchBox extends React.Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      initiatedLoad: false,
      firstFocus: true,
      input: "",
      suggestedLinks: [
        { "displayText": "Congress", "url": "https://congress.ache.org/" },
        { "displayText": "Bookstore", "url": "https://www.ache.org/learning-center/publications/books" },
        { "displayText": "Earn My FACHE", "url": "https://www.ache.org/fache" }
      ],
      dropdownClass: ''
    };
  }

  componentDidUpdate() {
    if (this.state.dropdownClass === '__active') {
      document.addEventListener("mousedown", this.handleClickOutside);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading) {
      this.setState({ initiatedLoad: false });
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      document.activeElement.blur();
      event.target.focus();
      this.unfocusSearchBox(event);
      document.removeEventListener("mousedown", this.handleClickOutside);
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.unfocusSearchBox(event);
    if (this.state.input.length) {
      this.props.handleEntry(this.keywordInput.value);
      this.setState({ initiatedLoad: true });
    }
  }

  manageInput(event) {
    const input = event.target;
    if (input != null && input.value != this.state.input) {
      this.setState({ input: input.value });
    }
    this.createSuggestedLinksList(event.target.value);
  }

  manageRef(input) {
    this.keywordInput = input
    if (input != null && input.value != this.state.input) {
      this.setState({ input: input.value });
    }
  }

  createSuggestedLinksList(input) {
    const trimmedInput = input.trim().toLowerCase();
    let updatedLinks = [];

    if (trimmedInput.length > 0) {
      SearchDropdown.links.forEach((link, i) => {
        if (link.displayText.toLowerCase().includes(trimmedInput)) {
          updatedLinks.unshift(link)
        }
        else if (link.keyWords.includes(trimmedInput)) {
          updatedLinks.push(link)
        }
      })
    }

    SearchDropdown.defaults.forEach((link, i) => {
      if (link.displayText.startsWith(trimmedInput) && trimmedInput.length > 0) {
        updatedLinks.unshift(link)
      }
      else updatedLinks.push(link)
    })
    this.setState({ suggestedLinks: updatedLinks.slice(0, 3) })
  }

  focusSearchBox(event) {
    event.preventDefault()
    this.setState({ dropdownClass: '__active' })
  }
  unfocusSearchBox(event) {
    event.preventDefault()
    this.setState({ dropdownClass: '' })
  }

  render() {
    const { dictionary, keywords, loading } = this.props;
    if (keywords && this.state.firstFocus) {
      this.createSuggestedLinksList(keywords);
      this.setState({firstFocus: false})
    }
    const shouldShowSpinner = this.state.initiatedLoad && loading;
    return (
      <div className="search-box__container" ref={this.setWrapperRef}>
        <form className="search-box" onSubmit={e => this.handleSubmit(e)} noValidate>
          <div className="search-box__input">
            <label htmlFor="site-search" className="u-visuallyhidden">{dictionary.searchBoxLabel}</label>
            <input id="site-search"
              type="text"
              placeholder={dictionary.searchBoxPlaceholder}
              defaultValue={keywords || ""}
              ref={input => this.manageRef(input)}
              onChange={event => this.manageInput(event)}
              onFocus={e => this.focusSearchBox(e)}
              onClick={e => this.focusSearchBox(e)}
            />
            <div className="search-box__loader">
              {shouldShowSpinner ? <LoadingSpinner /> : null}
            </div>
            <button
              type="submit"
              className={this.state.input.length ? "search-box__button" : "search-box__button-null disabled btn--disabled"}
            > Search
            </button>
          </div>
        </form>
        <div className={`search-box__dropdown` + this.state.dropdownClass}>
          <div className="suggested-links__text">Suggested links</div>
          <ul className="suggested-links__list">
            {this.state.suggestedLinks.map((link, i) => (
              <li className="suggested-links__list-item">
                <a className="btn--link" href={link.url}>
                  {link.displayText}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

const { string, func, shape, bool } = React.PropTypes;

SearchBox.propTypes = {
  keywords: string,
  handleEntry: func.isRequired,
  loading: bool,
  dictionary: shape({
    searchBoxPlaceholder: string,
    searchBoxLabel: string
  }).isRequired
};
