import "./polyfill";
import $ from "jquery";
// export for others scripts to use
window.$ = $;
window.jQuery = $;

import "picturefill";
import { initSearchApp } from "./ACHE/search";
import { BookDetailPdf } from "./ACHE/BookPdfList";
import { BookReadMore } from "./ACHE/BookReadMore";
import { FeaturedSearch } from "./ACHE/FeaturedSearch";
import { PrimaryNav } from "./ACHE/navigation/PrimaryNav";
import { ViewAllExpand } from "./ACHE/ViewAllExpand";
import { ImageSlider } from "./ACHE/ImageSlider";
import { ObjectFitCaptionOverlay } from "./ACHE/ObjectFitCaptionOverlay";
import { NewsSlideshow } from "./ACHE/NewsSlideshow";
import { SecondaryNav } from "./ACHE/navigation/SecondaryNav";
import { DynamicBanner } from "./ACHE/DynamicBanner";
import { UserMenu } from "./ACHE/UserMenu";
import { SearchToggle } from "./ACHE/SearchToggle";
import { VideoBlock } from "./ACHE/VideoBlock";
import { ResourceSlider } from "./ACHE/ResourceSlider";
import { PromoSlideshow } from "./ACHE/PromoSlideshow";
import { WffmHacks } from "./ACHE/WffmHacks";
import { TruncateText } from "./ACHE/TruncateText";
import { JobFeedSlider } from "./ACHE/JobFeedSlider";
import { Ads } from "./ACHE/Ads";
import { Accordion } from "./ACHE/accordion.js";
import { AccordionList } from "./ACHE/AccordionList";
import initializeTables from "./ACHE/ResponsiveTables";
import "./ACHE/ProfileBrowser";
import "./ACHE/Tabs";
import "./ACHE/UtilityNav";
import "./ACHE/showMoreBooks";

// PrimaryNav
const $primaryNavEl = $(".js-primary-nav");
if ($primaryNavEl.length) {
  let primaryNav = new PrimaryNav($primaryNavEl);
}

// Expandable Listings
const viewAllables = $.makeArray($(".js-view-all-expand")).map((el) => {
  return new ViewAllExpand($(el));
});

//accordions
const $accordionList = $.makeArray($(".js-accordion-list")).map((el) => {
  new AccordionList($(el));
});

// Slideshows

// do not intialize slideshows inside
// page editor
if (!$("body").hasClass("is-page-editor")) {
  const slideshows = $.makeArray($(".slideshow .js-slider")).map((el) => {
    return new ImageSlider($(el), {});
  });
}

if (!$("body").hasClass("is-page-editor")) {
  const newsSlideshows = $.makeArray($(".news-slideshow .js-slider")).map(
    (el) => {
      return new NewsSlideshow($(el), {});
    }
  );
}

if (!$("body").hasClass("is-page-editor")) {
  const jobFeedSlider = $.makeArray($(".job-feed.js-slider")).map((el) => {
    return new JobFeedSlider($(el), {});
  });
}

if (!$("body").hasClass("is-page-editor")) {
  const ads = $.makeArray($(".js-ad")).map((el) => new Ads($(el), {}));

  const interstitialAds = $.makeArray($(".js-ad-interstitial")).map(
    (el) => new Ads($(el), {})
  );
}

if (!$("body").hasClass("is-page-editor")) {
  const newsSlideshows = $.makeArray($(".related-resources .js-slider")).map(
    (el) => {
      return new ResourceSlider($(el), {});
    }
  );
}

if (!$("body").hasClass("is-page-editor")) {
  const promoSlideshows = $.makeArray($(".promo-slideshow .js-slider")).map(
    (el) => {
      return new PromoSlideshow($(el), {});
    }
  );
}

// Secondary Nav
if ($(".js-secondary-nav").length) {
  const secondaryNav = new SecondaryNav($(".js-secondary-nav"));
}

//Book Detail Page Expandable PDF list
if ($(".js-microsite-nav--books").length) {
  const bookDetailPdf = new BookDetailPdf($(".js-microsite-nav--books"));
}

//Book Detail Page Expandable Description
if ($(".js-book-details__description").length) {
  const bookReadMore = new BookReadMore($(".js-book-details__description"));
}

// User Menu
if ($(".js-user-menu").length) {
  const userMenu = new UserMenu($(".js-user-menu"));
}

if ($(".js-video-block").length) {
  const videoBlocks = $.makeArray($(".js-video-block")).map((el) => {
    return new VideoBlock($(el));
  });
}

if ($("form[data-wffm]").length) {
  const wffmForm = new WffmHacks($("form[data-wffm]"));
}

// Dynamic Banners (Hero and Page Banner)
const $banners = $(".js-dynamic-banner");
if ($banners.length) {
  const dynamicBanners = $.makeArray($banners).map((el) => {
    return new DynamicBanner($(el));
  });
}
// Re-usable object fit
const $objectFit = $(".js-object-fit");
if ($objectFit.length) {
  const objectFitCaptionOverlay = $.makeArray($objectFit).map((el) => {
    return new ObjectFitCaptionOverlay($(el));
  });
}

//Featured Search Bar
if ($(".js-featured-search").length) {
  const featuredsearch = new FeaturedSearch($(".js-featured-search"));
}

//Truncate Text
const $textToTruncate = $(".js-truncate");
if ($textToTruncate.length) {
  const truncatedText = $.makeArray($textToTruncate).map((el) => {
    return new TruncateText($(el));
  });
}

// Search Apps
// only init if results are included
if ($(".js-search-results").length) {
  initSearchApp(
    $.makeArray($(".js-search-query,.js-search-results,.js-search-facets"))
  );
}
if ($(".js-resource-search-results").length) {
  initSearchApp(
    $.makeArray($(".js-search-query,.js-search-results,.js-search-facets"))
  );
}

if ($(".rich-text table").length) {
  initializeTables();
}

if (!$("body").hasClass("is-page-editor")) {
  //only edit qna answer classes outside of page editor
  if ($(".qna__answer").length) {
    $(".qna__answer").last().css("border-bottom", "0");
  }
  if ($(".answer").length) {
    $(".answer").last().css("border-bottom", "0");
  }
}
$("document").ready(function () {
  if ($(".js-agenda__session_summary").length) {
    $(".js-agenda__session_summary").each(function () {
      if ($(this).text().length > 300) {
        let summary = $(this).text().substring(0, 300) + "...";
        $(this).html(summary);
      }
    });
  }
});

//seminar line-up highlights

$(document).ready(function() {
  var filtersWrapper = $(".seminar-line-up-component__filters");
  var filterItem = $(".seminar-line-up-component__filter");
  var filterByDate = $(".byDate");
  var filterByCredits = $(".byCredits");
  var resultsByDate= $(".seminar-line-up-component__byDate");
  var resultsByCredits = $(".seminar-line-up-component__byCredits");

  if ($(filtersWrapper).length > 0) { 
    $(resultsByCredits).hide();
    $(filterItem).click(function () {
      $(filterItem).removeClass('active');
      $(this).addClass('active');
    });

    $(filterByCredits).click(function () {
      $(resultsByCredits).show();
      $(resultsByDate).hide();
    });

    $(filterByDate).click(function () {
      $(resultsByDate).show();
      $(resultsByCredits).hide();
    });
  }
});