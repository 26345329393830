import $ from "jquery";
import "core-js/fn/object";
import Vimeo from "@vimeo/player";
import Youtube from "youtube-player";
import URI from "urijs";

export class VideoBlock {

  constructor($el) {
    this.$el = $el;
    this.$playButton = $el.find('.js-play-button');
    this.player = {};
    this.videoType = $el.attr("data-videoType").toLowerCase();
    this.videoMountPoint = $el.attr("data-videoId");
    this.video = $el.attr("data-video");

    this.initialize();
    this.bindEvents();
  }

  bindEvents() {
    this.$playButton.click((event) => this.playVideo(event));
  }

  initialize() {
    if (this.videoType === "youtube") {
      let videoUrl = new URI(this.video);
      let videoId = videoUrl.segment()[videoUrl.segment().length - 1];

      this.player = Youtube(this.videoMountPoint, {
        videoId: videoId,
        playerVars: {
          rel: "0"
        }
      });
      
      this.player.on('stateChange', (event) => this.handleYoutubeState(event));

    } else if (this.videoType === "vimeo") {
      this.player = new Vimeo(this.videoMountPoint, {url: this.video});

      this.handleVimeoState();
    }
  }

  handleYoutubeState(event) {
    /*
      State codes are:
      -1 (unstarted)
      0 (ended)
      1 (playing)
      2 (paused)
      3 (buffering)
      5 (video cued).
    */

    if (event.data === 1 || event.data === 3) {
      this.hideButton();
    } else {
      this.showButton();
    }
  }

  handleVimeoState() {
    this.player.on('play', (data) => {
      this.hideButton();
    });

    this.player.on('pause', (data) => {
      this.showButton();
    });
  }

  hideButton() {
    this.$playButton.addClass('is-hidden');
  }

  showButton() {
    this.$playButton.removeClass('is-hidden');
  }

  playVideo() {
    if (this.videoType === "youtube") {
      this.player.playVideo();
    } else if (this.videoType === "vimeo") {
      this.player.play().then(() => {
        this.hideButton();
      });
    }
  }
}
