import $ from 'jquery';
import "./custom-vendor/slick-carousel/slick/slick";

export class ImageSlider {
  constructor($el, conf) {
    this.$el = $el;
    let slickConf = Object.assign(this.slickDefaults(), conf);
    // bind event prior to slick init
    this.bindEvents();
    this.$el.slick(slickConf);
  }

  //get accurate height of the image to place the navigation between the description and the image.
  slickSetTop(target) {
    let $slideshow = $(target);
    let imageHeight = $slideshow.find(".slick-active").find("img").innerHeight();
    let $slickDots = $slideshow.find(".slick-dots");
    $slickDots.css("top", imageHeight);
    $slickDots.css("bottom", "auto");
    $slickDots.css("position", "absolute");
    $slideshow.children(".slick-arrow").each(function(){
      $(this).css("top", imageHeight/2);
    })
  }

  bindEvents() {
    // bind an event on the arrows to de-focus them when
    // you mouse out, so the focus state doesn't get stuck
    this.$el.on('mouseleave', '.slick-next, .slick-prev', event => {
      event.currentTarget.blur();
    });
    this.$el.on("setPosition", (currentSlide, event) => {
      let target = currentSlide.currentTarget;
      this.slickSetTop(target);
    });
    $(window).on("resize", () =>{
      $(".slick-dots").removeAttr("style");
    });
    this.$el.on("init", (slick) => {
      let target = slick.currentTarget;
      this.slickSetTop(target);
    });
  }

  slickDefaults() {
    return {
      dots: true,
      infinite: true,
      speed: 300,
      slide: ".image-block",
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: `<button type="button" class="slick-prev" tabindex="0">
          Previous
          <div class="icon"><svg><use xlink:href="/assets/ACHE/img/svg-sprite.svg#chevron-left"></use></svg></div>
      </button>`,
      nextArrow: `
   <button type="button" class="slick-next" tabindex="0">
       Next
       <div class="icon"><svg></sbg><use xlink:href="/assets/ACHE/img/svg-sprite.svg#chevron-right"></use></svg></div>
    </button>`,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: true,

          }
        }
      ]

    }
  }

}
