import $ from "jquery";
import { Accordion } from "./accordion.js";

export class AccordionList {
  constructor($el) {
    this.$el = $el;
    this.$toggleAll = $el.find('.js-toggle-all');
    this.accordionsArray = Array.from($el.get(0).querySelectorAll(".js-accordion"));
    this.accordions = this.accordionsArray.map((el) => {
        return Accordion(el);
    });
    this.numOpen = 0;
    this.bindEvents();
  }

  bindEvents() {
    this.$el.find(".js-accordion-link").on('click', ()=>{
        this.numOpen = 0;
        $(this.accordions).each((i, el) => {
            if(el.getOpenState()){
                this.numOpen++;
            }
        });
        this.updateToggleText();
    });


    this.$toggleAll.on('click', (event) => {
        if(this.numOpen == this.accordionsArray.length){
            $(this.accordions).each((i, el) => {
                el.toggleContent(false);
            });
            this.numOpen = 0;
        }else{
            this.numOpen = 0;
            $(this.accordions).each((i, el) => {
                el.toggleContent(true);
                this.numOpen++;
            });
        }
        this.updateToggleText();
    });
  }

  updateToggleText(){
    if(this.numOpen == this.accordionsArray.length){
        this.$toggleAll.text("Collapse All");
    }else{
        this.$toggleAll.text("Expand All");
    }
  }
}
