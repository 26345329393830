import React from "react";
import "react-dates/initialize";
import {
  closeFilters,
  setFiltersOpenDirection,
  manageContainerWhitespace
} from "../filterSlider";
import classnames from "classnames";
import URI from "urijs";
import {
  DateRangePicker,
  startDatePlaceholderText,
  endDatePlaceholderText,
  isInclusivelyBeforeDay,
  SingleDatePicker,
  DayPickerRangeController,
  orientation,
  withPortal
} from "react-dates";
import moment from "moment";
export default class Datepicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      dateString: null,
      displayError: false //this lets us know if they've tried to update the datepicker
    };
    if (this.props.date) {
      const pubdate = this.props.date.split(";");
      this.state.startDate = moment(pubdate[0], "MM/DD/YYYY");
      this.state.endDate = moment(pubdate[1], "MM/DD/YYYY");
    }
    this.clearDateRange = this.clearDateRange.bind(this);
  }

  componentWillMount() {
    let dateQuery = URI(window.location.href).search(true).date;
    const splitDate = dateQuery ? dateQuery.split(";") : [];
    this.setState({
      dateString: dateQuery,
      startDate: splitDate.length ? this.stringToMoment(splitDate[0]) : null,
      endDate: splitDate.length > 1 ? this.stringToMoment(splitDate[1]) : null
    });
  }

  momentToString(date) {
    return date ? date.format("MM/DD/YYYY") : "*";
  }

  stringToMoment(date) {
    return !date || date === "*" ? null : moment(date);
  }
  clearDateRange() {
    this.setState(
      {
        startDate: null,
        endDate: null,
        dateString: null,
        displayError: false
      },
      () => {
        this.updateDateRangeFacet(null);
      }
    );
  }

  //custom date picker facet hey-o
  updateDateRangeFacet(date) {
    if (date) {
      let startDate = date.startDate;
      let endDate = date.endDate;
      let startString = startDate ? startDate.format("L") : null;
      let endString = endDate ? endDate.format("L") : null;
      this.setState({
        startDate: startDate,
        endDate: endDate
      });
      if (startString && endString) {
        this.setState(
          {
            dateString: startString + ";" + endString,
            displayError: false
          },
          function() {
            this.props.handleDateChange(this.state.dateString); // action creator
            if (this.props.handleDateDurationChange) {
              this.props.handleDateDurationChange(null);
            }
          }
        );
      } else {
        this.setState({ displayError: true });
      }
    }
  }

  createDate(dateStr) {
    //TODO this exists to clean up manually entered strings if we can ever target values before they become null
    if (typeof dateStr == "string") {
      let splitStr = dateStr.includes("-")
        ? "-"
        : dateStr.includes(".")
          ? "."
          : dateStr.includes("/")
            ? "/"
            : null;
      let [month, day, year] = dateStr.split(splitStr);
      let newDay = day.length < 2 ? "0" + day : day;
      let newMonth = day.length < 2 ? "0" + month : day;
      let newDate = new Date(newMonth + "/" + newDay + "/" + year);
      return moment(newDate);
    } else if (typeof dateStr == "object") {
      return dateStr;
    } else {
      return moment(new Date(dateStr));
    }
  }

  renderDateRange() {
    let datepickerStatusClass; //this will let us do an IE friendly version of placeholder-shown
    if (this.state.startDate && !this.state.endDate) {
      datepickerStatusClass = "search-facets__datepickers__populated-start";
    } else if (!this.state.startDate && this.state.endDate) {
      datepickerStatusClass = "search-facets__datepickers__populated-end";
    } else if (this.state.startDate && this.state.endDate) {
      datepickerStatusClass = "search-facets__datepickers__populated-both";
    }
    let datepickerErrorClass;
    //this datepickererror display is commented out until we are able to target the input value for manual dates.
    // if (this.state.displayError == true) {
    //   datepickerErrorClass = "search-facets__datepickers__error--shown";
    // } else {
    //   datepickerErrorClass = "";
    // }
    return (
      <div className={datepickerStatusClass + " search-facets__datepickers"}>
        {/* <span className={datepickerErrorClass + " search-facets__datepickers__error"}>
        <svg className="icon">
          <use xlinkHref="/assets/ACHE/img/svg-sprite.svg#warning-triangle"></use>
        </svg>
        Datepicker requires MM/DD/YYYY format</span> */}
        <DateRangePicker
          startDate={this.state.startDate}
          // momentPropTypes.momentObj or null,
          startDateId="datepicker_start"
          // PropTypes.string.isRequired,
          startDatePlaceholderText="From"
          isOutsideRange={() => false}
          endDate={this.state.endDate}
          endDateId="datepicker__end"
          onDatesChange={(startDate, endDate) =>
            this.updateDateRangeFacet(startDate, endDate)
          }
          onClose={({ startDate, endDate }) =>
            this.setState({ startDate, endDate })
          }
          // PropTypes.func.isRequired,
          noBorder={true}
          numberOfMonths={1}
          orientation={orientation}
          keepFocusOnInput={false}
          endDatePlaceholderText="To"
          focusedInput={this.state.focusedInput}
          readOnly
          // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={focusedInput => {
            this.setState({ focusedInput });
          }}
        />
      </div>
    );
  }

  toggleGroup() {
    const isOpen = !this.state.isGroupOpen;
    this.setState({ isGroupOpen: isOpen });
  }
  render() {
    return <div>{this.renderDateRange()}</div>;
  }
}

const {
  string,
  arrayOf,
  bool,
  shape,
  number,
  oneOfType,
  func
} = React.PropTypes;
