import React from "react";
import R from "ramda";
import classnames from "classnames";
import Downshift from "downshift";
import moment from "moment";
import URI from "urijs";
import {
  valuesWithSelectedChildren,
  selectedFacetsFromGroup
} from "../redux/facets";
import Datepicker from "./Datepicker.jsx";

const MAX_FACETS_SHOWN = 4;
const MAX_FACETS_SHOWN_EXPANDED = 10;

export default class FacetGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isGroupOpen: this.props.isOpen,
      isGroupExpanded: false,
      valuesExpanded: [],
      contentFilter: null,
      eventDate: null,
      eventDurationRange: null
    };
    this.deselectEventDuration = this.deselectEventDuration.bind(this);
  }

  // Lifecycle

  componentWillMount() {
    // if group is closed, but has selected
    // values, open it
    const group = this.props.group;
    let params = URI.parseQuery(document.location.href);
    let eventSpan = params.eventSpan ? params.eventSpan : null;
    let rpContentType = params.contentFilter ? params.contentFilter : null;

    const selected = selectedFacetsFromGroup(group);
    let shouldOpen = this.state.isGroupOpen;
    if (selected.length > 0 || (group.id == "EventDuration" && eventSpan)) {
      shouldOpen = true;
    }
    if (eventSpan) {
      this.eventDurationCalculation(eventSpan);
    }

    //hacky select all rpContentTypes if they selected view all on another page
    if (
      rpContentType &&
      group.id == "RPContentType" &&
      rpContentType == "RPAll"
    ) {
      this.props.handleSelectGroup(group);
    }
    // Some facets will be expanded by default due to
    // children being pre-selected
    const hasSelected = valuesWithSelectedChildren(group);
    const selectedIds = hasSelected.map(v => v.id);

    // similarly, if group's values or value children
    // are selected, auto-expand that group
    const tailValues = R.drop(MAX_FACETS_SHOWN, group.values);
    const shouldExpand = tailValues.reduce((should, tv) => {
      if (tv.selected === true || selectedIds.indexOf(tv.id) >= 0) {
        should = true;
      }

      return should;
    }, false);

    this.setState({
      isGroupOpen: shouldOpen,
      isGroupExpanded: shouldExpand,
      valuesExpanded: selectedIds,
      eventSpan: null,
      contentFilter: null
    });
  }
  shouldComponentUpdate(nextProps, nextState) {
    //this will let us edit or state if our url is updated by an outside component, like the ClearAll or manual edits.
    let params = URI.parseQuery(document.location.href);
    let eventSpan = params.eventSpan ? params.eventSpan : null;
    if (eventSpan !== this.state.eventDurationRange) {
      this.setState({ eventDurationRange: eventSpan });
    }
    if (this.state.eventDurationRange !== nextState.eventDurationRange) {
      if (nextState.eventDurationRange) {
        const eventDurationRange = nextState.eventDurationRange;
        this.setState({
          eventDurationRange: eventDurationRange
        });
      } else {
        this.setState({ eventDurationRange: null });
      }
    }
    return true;
  }
  chunkValues(values) {
    // divide the values into two arrays, the first for the Top X facets
    // and the second for overflow. If recursion level is over zero, put
    // all values in first array.
    return values.length > MAX_FACETS_SHOWN
      ? R.splitAt(MAX_FACETS_SHOWN, values)
      : [values, []];
  }

  showExpandButton() {
    return !this.state.isGroupExpanded;
  }

  shouldRenderTailValues(tailValues) {
    return this.state.isGroupExpanded && tailValues.length > 0;
  }

  shouldRenderChildGroups(group) {
    return group.isOpen && !!group.childGroups && group.childGroups.length > 0;
  }

  toggleGroup() {
    const isOpen = !this.state.isGroupOpen;
    this.setState({ isGroupOpen: isOpen });
  }

  toggleExpand() {
    const isExpanded = !this.state.isGroupExpanded;
    this.setState({ isGroupExpanded: isExpanded });
  }
  eventDurationCalculation(eventInputValue) {
    const today = new Date();
    let range;
    if (this.state.eventDurationRange != null) {
      this.deselectEventDuration(event);
    }
    if (eventInputValue == "Upcoming") {
      range = moment(today).add(5, "y");
    } else if (eventInputValue == "Past") {
      range = moment(today).subtract(1, "y");
    }
    const dateRange = moment(range).isSameOrAfter(today)
      ? moment(today).format("L") + ";" + moment(range).format("L")
      : moment(range).format("L") + ";" + moment(today).format("L");
    this.setState(
      {
        eventDate: dateRange,
        eventDurationRange: eventInputValue
      },
      function() {
        this.props.handleDateChange(this.state.eventDate); // action creator
        this.props.handleDateDurationChange(this.state.eventDurationRange);
      }
    );
  }
  eventDuration(event) {
    const eventInput = event.target;
    const eventInputValue = eventInput.value;
    const currentRange = this.state.eventDurationRange;
    if (eventInputValue == currentRange) {
      this.deselectEventDuration(event);
    } else {
      this.eventDurationCalculation(eventInputValue);
      eventInput.checked = true;
    }
  }
  deselectEventDuration(event) {
    const currentEventDurationRange = this.state.eventDurationRange;
    const eventInput = event ? event.target : null;
      if (this.refs["eventdate"]) {
          this.refs["eventdate"].clearDateRange();
      }
    this.setState(
      {
        eventDate: null,
        eventDurationRange: null
      },

      function() {
        this.props.handleDateChange(this.state.eventDate);
        this.props.handleDateDurationChange(this.state.eventDurationRange);
      }
    );
  }
  toggleValueExpand(valueId) {
    // check if the valueId is in the list. Remove it
    // if found, add it if not.
    const valueIdx = this.state.valuesExpanded.indexOf(valueId);
    const selectedValues =
      valueIdx >= 0
        ? R.remove(valueIdx, 1, this.state.valuesExpanded)
        : R.append(valueId, this.state.valuesExpanded);

    this.setState({ valuesExpanded: selectedValues });
  }

  valueIsExpanded(value) {
    return this.state.valuesExpanded.indexOf(value.id) >= 0;
  }

  onFacetChange(event, uniqueId) {
    this.props.handleFacetChange(uniqueId, event.target.checked); //changed to unqiueId to handle duplicates--based on child-parent relationship
  }

  onClearGroup() {
    const group = this.props.group;
    this.props.handleClearGroup(group);
    this.deselectEventDuration();
  }

  // Rendering
  clearGroupButton() {
    //I pulled this out here so I could use it on both normal and autocomplete facets.
    return (
      <div className="search-facets__group-top">
        <button
          onClick={() => this.onClearGroup()}
          className="search-facets__group-clear"
          type="button"
        >
          Clear
        </button>
      </div>
    );
  }
  autoComplete(facets) {
    let facetArr = [];
    const facetValue = facets.values;
    const facetLabel = facets.label;
    facetValue.map(
      val => (val.selected == false ? facetArr.push({id: val.id, uniqueId: val.uniqueId}) : null)
    );
    facetValue.map(val =>
      val.childValues.forEach(function(child) {
        facetArr.push({id: child.id, uniqueId: child.uniqueId});
      })
    );
    let filterLabel =  facets.id == "locations" && this.props.dictionary.limitByLocationLabel ? this.props.dictionary.limitByLocationLabel : "Search " + facetLabel;
    let sortFacets = R.sortBy(R.compose(R.toLower, R.prop('id')));;
    facetArr = sortFacets(facetArr);
    return (
      <Downshift
        onChange={selected => this.props.handleFacetChange(selected.uniqueId, true)}
        itemToString={item => (item ? item.id : '')}
        render={({
          getInputProps,
          getItemProps,
          getLabelProps,
          isOpen,
          defaultIsOpen,
          inputValue,
          highlightedIndex,
          selectedItem,
          setItemCount,
          clearSelection,
          setHighlightedIndex
        }) => (
          <div
            className={
              isOpen
                ? " search-facets__typeahead search-facets__typeahead--open"
                : "search-facets__typeahead"
            }
          >
            <label className="u-visuallyhidden" {...getLabelProps()}>
              Choose Facet
            </label>
            <input
              placeholder={filterLabel}
              {...getInputProps({
                onChange: e => {
                  if (e.target.value === "") {
                    clearSelection();
                    setHighlightedIndex(null);
                  }
                }
              })}
            />{" "}
            {isOpen ? (
              <div className="search-facets__typeahead__list">
                {facetArr
                  .filter(
                    item =>
                      !inputValue ||
                      item.id.toLowerCase().includes(inputValue.toLowerCase())
                  )
                  .map((item, index) => (
                    <div
                      className="search-facets__typeahead__list-item"
                      {...getItemProps({ item })}
                      key={item.id}
                    >
                      {selectedItem === index ? (
                        <span className="search-facets__checkbox--checked search-facets__checkbox" />
                      ) : highlightedIndex === index ? (
                        <span className="search-facets__checkbox search-facets__checkbox--hover" />
                      ) : (
                        <span className="search-facets__checkbox" />
                      )}
                      {item.id}
                    </div>
                  ))}
              </div>
            ) : (
              <div className="search-facets__typeahead__list"></div>
            )}
          </div>
        )}
      />
    );
  }

  renderValueItem(value, recursionLevel) {
    const childValues = value.childValues;
    const hasChildValues = !!childValues && childValues.length > 0;
    const isExpanded = this.valueIsExpanded(value);
    const renderChildValues = hasChildValues && isExpanded;
    const toggleClass = classnames("search-facets__value-expand", {
      "is-open": isExpanded
    });
    // close icon looks different depending on whether it's a level-0 or level 1 group
    let toggleIcon = recursionLevel >= 0 ? "triangle-right" : "chevron-right";
    return (
      <li key={value.uniqueId}>
        <input
          id={value.uniqueId}
          checked={value.selected}
          onChange={e => this.onFacetChange(e, value.uniqueId)}
          type="checkbox"
        />
        <span className="search-facets__checkbox" />

        <label htmlFor={value.uniqueId}>
          {value.name}
          <span className="search-facets__count">({value.count})</span>
        </label>
        {hasChildValues && (
          <button
            type="button"
            className={toggleClass}
            onClick={() => this.toggleValueExpand(value.id)}
          >
            <svg className="icon">
              <use
                xlinkHref={"/assets/ACHE/img/svg-sprite.svg#" + toggleIcon}
              />
            </svg>
          </button>
        )}
        {renderChildValues &&
          this.renderValues(childValues, recursionLevel + 1)}
      </li>
    );
  }

  renderFlatValues(values, groupId) {
    const eventArr = ["Single Day Event", "Multi Day Event"];
    const valueIds = R.pluck("id", values);
    const fakeFacet = this.state.eventDurationRange;
    const isEventDuration =
      R.intersection(eventArr, valueIds).length > 0
        ? true
        : groupId == "EventDuration"
          ? true
          : false;
    const renderValue = value => {
      return (
        <li key={value.uniqueId}>
          <input
            id={value.uniqueId}
            checked={value.selected}
            onChange={e => this.onFacetChange(e, value.uniqueId)}
            type="checkbox"
          />
          <span className="search-facets__checkbox" />
          <label htmlFor={value.uniqueId}>
            {value.name}
            <span className="search-facets__count">({value.count})</span>
          </label>
        </li>
      );
    };

    return (
      <div>
        <ul className="search-facets__group-values">
          {isEventDuration &&
            fakeFacet && (
              <li key={"EventDuration--" + fakeFacet}>
                <input
                  id={"EventDuration--" + fakeFacet}
                  checked="true"
                  onChange={e => this.deselectEventDuration(e)}
                  value={fakeFacet}
                  type="checkbox"
                />
                <span className="search-facets__checkbox" />
                <label htmlFor={"EventDuration--" + fakeFacet}>
                  {fakeFacet} Events
                </label>
              </li>
            )}
          {values.map(value => renderValue(value))}
        </ul>
        {valueIds.length > 0 || (isEventDuration && fakeFacet) ? (
          <span className="search-facets__hr">
            <hr />
          </span>
        ) : (
          ""
        )}
      </div>
    );
  }

  renderValues(values, recursionLevel) {
    const eventArr = ["Single Day Event", "Multi Day Event"];
    const valueIds = R.pluck("id", values);
    const isEventDuration =
      R.intersection(eventArr, valueIds).length > 0 ? true : false;
    const durationSelection = this.state.eventDurationRange;
    let chunkedValues;
    if (recursionLevel === 0) {
      chunkedValues = this.chunkValues(values);
    } else {
      chunkedValues = [values, []];
    }

    const headValues = chunkedValues[0];
    const tailValues = chunkedValues[1].slice(0,MAX_FACETS_SHOWN_EXPANDED-4);

    const stateClass = `search-facets__group-values is-level-${recursionLevel}`;
    const valuesWrapperClass = classnames("search-facets__values-wrapper", {
      "has-no-values": !values.length
    });
    const shouldRenderTailValues = this.shouldRenderTailValues(tailValues);

    return (
      <div className={valuesWrapperClass}>
        <ul className={stateClass}>
          {/* {isEventDuration &&
            durationSelection != "Past" && (
              <li key="EventDuration--Upcoming">
                <input
                  id="EventDuration--Upcoming"
                  onChange={e => this.eventDuration(e)}
                  value="Upcoming"
                  type="checkbox"
                  checked={durationSelection == "Upcoming" ? true : false}
                />
                <span className="search-facets__checkbox" />
                <label htmlFor="EventDuration--Upcoming">Upcoming Events</label>
              </li>
            )} */}
          {/* {isEventDuration &&
            durationSelection != "Upcoming" && (
              <li key="EventDuration--Past">
                <input
                  id="EventDuration--Past"
                  onChange={e => this.eventDuration(e)}
                  value="Past"
                  type="checkbox"
                  checked={durationSelection == "Past" ? true : false}
                />
                <span className="search-facets__checkbox" />
                <label htmlFor="EventDuration--past">Past Events</label>
              </li>
            )} */}
          {headValues.map(val => this.renderValueItem(val, recursionLevel))}
        </ul>
        {shouldRenderTailValues && (
          <ul className={classnames(stateClass, "is-overflow")}>
            {tailValues.map(val => this.renderValueItem(val, recursionLevel))}
          </ul>
        )}
      </div>
    );
  }
  renderChildGroups(group) {
    if (
      this.state.isGroupOpen &&
      group.childGroups &&
      group.childGroups.length
    ) {
      return group.childGroups.map(childGroup => {
        if (childGroup.values.length === 0) {
          return null;
        }

        return (
          <FacetGroup
            key={childGroup.id}
            group={childGroup}
            dupeSelected={false}
            handleFacetChange={this.props.handleFacetChange}
            dictionary={this.props.dictionary}
          />
        );
      });
    }
  }
  removeEmptyParameters = function(data) {
    var result = {};
    Object.keys(data)
      .filter(function(key) {
        return Boolean(data[key]) && data[key].length;
      })
      .forEach(function(key) {
        result[key] = data[key];
      });
    return result;
  };

  render() {
    const group = this.props.group;
    const selectedValues = selectedFacetsFromGroup(group);
    // const showDupeSelected = selectedValues.length > 0 && this.props.dupeSelected;
    const showDupeSelected = true;
    const groupClass = classnames("search-facets__group", {
      "is-open": this.state.isGroupOpen
    });
    const expandLabel = this.state.isGroupExpanded
      ? "- See Less"
      : "+ See More";
    const autoCompleteArr = ["topic", "RPAuthor", "locations"]; //authors, topics, locations
    let isAutoComplete = autoCompleteArr.includes(this.props.group.id);
    const manualSubGroup = [
      "RPAuthor",
      "RPContentType",
      "EventDuration",
      "EventType",
      "EventAttendance",
      "locations"
    ];
    let toggleIcon = manualSubGroup.includes(this.props.group.id)
      ? "triangle-right"
      : "chevron-right";
    const openClass = classnames("search-facets__group-toggle", {
      "is-open": this.state.isGroupOpen
    });
    return (
      <div className={groupClass}>
        <div className="search-facets__group-heading">
          <button
            className={openClass}
            type="button"
            onClick={e => this.toggleGroup(e)}
          >
            <svg className="icon">
              <use
                xlinkHref={"/assets/ACHE/img/svg-sprite.svg#" + toggleIcon}
              />
            </svg>
          </button>
          <h4>{group.label}</h4>
        </div>
        <div className="search-facets__group-wrapper">
          {this.state.isGroupOpen && (
            <div className="search-facets__group-body">
              {isAutoComplete && this.autoComplete(group)}
              {this.renderValues(group.values, 0)}{" "}
              {group.values.length > MAX_FACETS_SHOWN && (
                <div className="search-facets__group-more">
                  <button type="button" onClick={() => this.toggleExpand()}>
                    {expandLabel}
                  </button>
                </div>
              )}{" "}
              {this.renderChildGroups(group)}
              { group.id === "EventDuration" &&
                <Datepicker
                  handleDateChange={this.props.handleDateChange}
                  handleDateDurationChange={this.props.handleDateDurationChange}
                  ref="eventdate"
                />
              }
            </div>
          )}
        </div>
      </div>
    );
  }
}

const {
  func,
  shape,
  bool,
  number,
  string,
  oneOfType,
  arrayOf
} = React.PropTypes;

// allows us to define propTypes recursively
// https://github.com/facebook/react/issues/5676
const lazyF = f => (...args) => f().apply(this, args);

const facetValueShape = shape({
  id: oneOfType([number, string]).isRequired,
  name: string.isRequired,
  count: number.isRequired,
  selected: bool.isRequired,
  childValues: arrayOf(lazyF(() => facetValueShape))
});

const facetGroupShape = shape({
  id: oneOfType([number, string]).isRequired,
  label: string.isRequired,
  values: arrayOf(facetValueShape).isRequired,
  childGroups: arrayOf(lazyF(() => facetGroupShape))
});

FacetGroup.propTypes = {
  isOpen: bool,
  dupeSelected: bool,
  handleFacetChange: func.isRequired,
  // handleClearGroup: func.isRequired,
  // handleSelectFacetGroup: func.isRequred,
  group: facetGroupShape
};

FacetGroup.defaultProps = {
  isOpen: true,
  dupeSelected: true
};
