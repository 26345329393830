import R from "ramda";
import { flattenObj } from "../util";

export function allFacetValuesFromGroup(group) {
  let allValues = [];

  const grabAllValues = value => {
    allValues.push(value);
    if (value.childValues.length > 0) {
      value.childValues.forEach(cv => grabAllValues(cv));
    }
  };

  group.values.forEach(value => grabAllValues(value));

  if (group.hasOwnProperty("childGroups")) {
    group.childGroups.forEach(childGroup => {
      childGroup.values.forEach(value => grabAllValues(value));
    });
  }

  return allValues;
}

// Returns an object with arrays of selected facet value objects, using
// their root group name as a key
export function selectedFacetsByGroup(facets) {
  let facetsByGroup = {};
  facets.forEach(group => {
    const selectedFacets = selectedFacetsFromGroup(group);
    if (selectedFacets.length) {
      facetsByGroup[group.id] = selectedFacetsFromGroup(group);
    }
  });

  return facetsByGroup;
}

// Returns an object with arrays of selected facet ids, using
// their root group name as a key
export function selectedFacetIdsByGroup(facets) {
  const selectedFacets = selectedFacetsByGroup(facets);
  Object.keys(selectedFacets).forEach(k => {
    selectedFacets[k] = selectedFacets[k].map(f => f.id);
  });

  return selectedFacets;
}

// Given an array of facet value objects, return an
// a flat array of selected facet value object, including
// selected child values.
export function selectedFacetsFromValues(values) {
  let selected = values.filter(v => v.selected === true);
  values.forEach(value => {
    if (value.hasOwnProperty("childValues")) {
      selected = selected.concat(selectedFacetsFromValues(value.childValues));
    }
  });

  return selected;
}

// Given a facet group, return a flat array of selected
// facets value object, including child groups and all child values.
export function selectedFacetsFromGroup(group) {
  let selected = selectedFacetsFromValues(group.values);
  if (group.hasOwnProperty("childGroups")) {
    group.childGroups.forEach(childGroup => {
      selected = selected.concat(selectedFacetsFromGroup(childGroup));
    });
  }

  return selected;
}

export function allSelectedFacets(facets) {
  return facets.reduce((all, group) => {
    return all.concat(selectedFacetsFromGroup(group));
  }, []);
}

export function groupHasSelectedChildren(group) {
  return selectedFacetsFromGroup(group).length > 0;
}

export function valueHasSelectedChildren(value) {
  // does not include selection state of group itself
  let selectedChildren = [];
  if (value.hasOwnProperty("childValues")) {
    selectedChildren = selectedFacetsFromValues(value.childValues);
  }

  return selectedChildren.length > 0;
}

// An array of values who have a child value
// selected. Does not require the value itself
// be selected.
export function valuesWithSelectedChildren(group) {
  const all = allFacetValuesFromGroup(group);
  return all.reduce((hasSelected, value) => {
    if (valueHasSelectedChildren(value)) {
      hasSelected.push(value);
    }

    return hasSelected;
  }, []);
}

// Returns an array of object path segments that
// resolves to the facet value object in the
// provided facets state object. This is useful
// when needing to make immutable changes using
// R.assocPath or R.lensPath

// TODO: This performs acceptably for now
// but is definitely not optimal for very large data sets.
// Should consider a different approach to setting deeply nested data.
// export function pathFromValueId(valueId, facets) {
//    First, flatten the facets object into k,v pairs of properties
//    and stringified "path" keys. Then use the paths to
//    lookup the facet value
//   const facetLookup = R.invertObj(flattenObj(facets));
//   const delimitedPath = facetLookup[valueId];
//   console.log({facetLookup,facets});
//
//    Our paths are in format "a.b.0.c', so convert into ["a", "b", 0, "c"].
//    Then pop off the last path segment so it points to the entire value
//    object, instead of just the id.
//   let pathArr = delimitedPath.split(".");
//   pathArr.pop();
//
//    Finally, ensure numerical "keys" are properly cast to Numbers
//    or else Ramda will treat them as object keys and
//    convert arrays to objects when using assocPath or lens methods.
//   pathArr = pathArr.map( segment => {
//     const numberified = parseInt(segment, 10);
//     return isNaN(numberified)
//       ? segment
//       : numberified;
//   });
//   return pathArr;
// }
export function pathFromUniqueId(uniqueId, groups) {
  const mapIndexed = R.addIndex(R.map);

  const lookIn = (path, obj) => {
    if (R.propEq("uniqueId", uniqueId, obj)) {
      return path;
    } else if (Array.isArray(obj)) {
      return R.compose(R.flatten, mapIndexed((sub, i) => lookIn(path.concat(i), sub)))(obj);
    } else if (R.has("values", obj)) {
      return lookIn(path.concat("values"), obj.values);
    } else if (R.has("childValues", obj)) {
      return lookIn(path.concat("childValues"), obj.childValues);
    } else {
      return [];
    }
  };
  return lookIn([], groups);

}

// Return a new facets object with the give facet value selected
export function setSelectedOnFacetValues(uniqueIds, selected, facets) {
  // Lookup the path for the given facetId

    // and change it using assocPath
  if (!uniqueIds.length) {
    return facets;
  }
  const paths = uniqueIds.map(uniqueId => {
    let pathArr = pathFromUniqueId(uniqueId, facets);
    // add "selected" to the end of our path
    if (pathArr.length === 0) {
      console.error("couldn't find unique id " + uniqueId);
    } else {
      pathArr.push("selected");
    }
    return pathArr;
  }).filter(path => path.length > 0);
  return paths.reduce((newFacets, path) => {
    // NOTE: Using numerical indexes in assocPath only work in
    // Ramda 0.23.0+
    return R.assocPath(path, selected, newFacets);
  }, facets);
}
