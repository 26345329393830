import $ from "jquery";

// const $profileBio = $(".js-profile-browser__biography");
const $profileBio = $(".profile-browser__biography");
$(document).ready(function(){
  if ($profileBio.length > 0) {
      $profileBio.each(function(){
          let bio = $(this).text();
          let newBio =  bio.slice(0, 219) + "...";
          $(this).html(newBio);
      })
  }
  
})


