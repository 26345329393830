import $ from 'jquery';

export class TruncateText {
    constructor($el) {
        this.$el = $el;
        this.$maxLength = this.$el.attr("data-maxlength");

        console.log(this.$el.text().length);
        console.log(this.$maxLength);
        if (this.$el.text().length > this.$maxLength) {
            this.$el.html(this.$el.text().substr(0, this.$maxLength).concat("..."));
        }
    }
}