import $ from "jquery";
import "./custom-vendor/slick-carousel/slick/slick";

// @TODO: let people know youre sharing that 1024 breakpoint
export class JobFeedSlider {
  constructor($el, conf) {
    this.slickConf = Object.assign(this.slickDefaults(), conf);

    this.$el = $el;
    this.$jobs = this.$el.find(".job-feed__item");
    this.numberOfSlides = 0;
    this.activeslide = 0;

    // bind event prior to slick init
    this.bindEvents();
    this.$el.slick(this.slickConf);
  }

  bindEvents() {
    // bind an event on the arrows to de-focus them when
    // you mouse out, so the focus state doesn't get stuck
    this.$el.on("mouseleave", ".slick-next, .slick-prev", event => {
      event.currentTarget.blur();
    });

    this.$el.on("beforeChange", event => {});

    this.$el.on("afterChange", event => {
      this.controlBackButton();
    });

    if (window.innerWidth <= 767) {
      this.groupItemsBy(3);
    } else if (window.innerWidth >= 768 && window.innerWidth <= 910) {
      this.groupItemsBy(6);
    } else {
      this.groupItemsBy(9);
    }

    let resizeTimer;

    $(window).on("resize", event => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        /*Run this function at the end of the resizing event.
          Using this cause other methods broke the slider on tablet orientation change
        */
        if (window.innerWidth <= 767) {
          this.$el.slick("unslick");
          this.groupItemsBy(3);
          this.$el.slick(this.slickConf);
        } else if (window.innerWidth >= 768 && window.innerWidth <= 910) {
          this.$el.slick("unslick");
          this.groupItemsBy(6);
          this.$el.slick(this.slickConf);
        } else {
          this.$el.slick("unslick");
          this.groupItemsBy(9);
          this.$el.slick(this.slickConf);
        }
      }, 250);
    });
  }

  /**
   * Wraps the given number of items in divs so that they are displayed as a single slide when slick-ified
   * @param size the number of jobs that should display on one slide
   */
  groupItemsBy(size) {
    let array = this.$jobs.slice();
    let groupedItems = $.makeArray(array)
      .map((item, i) => {
        return i % size === 0 ? array.slice(i, i + size) : null;
      })
      .filter(function(item) {
        return item;
      });

    this.$el.empty();

    $.makeArray(groupedItems).map(group => {
      let container = $('<ul class="job-feed__slide-container"></ul>');
      let slide = $('<div class="job-feed__slide"></div>');
      $(container).append(group);
      $(slide).append(container);
      this.$el.append(slide);
    });
  }

  controlBackButton() {
    this.activeslide = this.$el.slick("slickCurrentSlide");
    this.$el.addClass("back-button-is-visible");

    // if (this.activeslide != 0) {
    //   this.activeslide = this.$el.slick('slickCurrentSlide');
    // } else {
    //   this.$el.removeClass("back-button-is-visible");
    // }
  }

  slickDefaults() {
    return {
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      prevArrow: `
      <button type="button" class="slick-prev" tabindex="0">
          Previous slide
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
          <path fill="#424242" d="M15.422 16.078l-1.406 1.406-6-6 6-6 1.406 1.406-4.594 4.594z"></path>
        </svg>
       </button>`,
      nextArrow: `
        <button type="button" class="slick-next" tabindex="0">
            Next slide
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
            <path fill="#424242" d="M8.578 16.359l4.594-4.594-4.594-4.594 1.406-1.406 6 6-6 6z"></path>
            </svg>
         </button>`,
      responsive: [
        {
          breakpoint: 910,
          settings: {
            slidesToShow: 1
          }
        },
        {
          breakpoint: 911,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    };
  }
}
