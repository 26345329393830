import $ from "jquery";
// TODO build new url with correct filters
export class FeaturedSearch {
  constructor($el, conf) {
    this.$el = $el;
    this.bindEvents();
  }

  bindEvents() {

    // this.$el.on("submit", event => {
    //   event.preventDefault();

    //   this.redirectWindow(this);
    // });
    this.$el.find("li").on("click", event => {
        this.selectRadio(event)
    });
  }

  redirectWindow(form) {
    //   console.log(form);
    // $(form)
    //   .find("#site-search")
    //   .val();
    return false;

  }
  selectRadio(li){
    this.$el.find("input[type=radio]").prop("checked", false);
    $(li.currentTarget).find("input").prop('checked', true);

  }

}
