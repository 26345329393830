import { connect } from "react-redux";
import FacetList from "../components/FacetList.jsx";
import {
  loadFacetChange,
  loadClearFacetGroup,
  loadSelectFacetGroup,
  loadClearAllFacets,
  hideMobileFacets,
  loadDateRangeChange,
  loadEventDateRangeChange,
  loadEventSpanChange
} from "../redux/search/search.actions";
import { allSelectedFacets } from "../redux/facets";

const mapStateToProps = (state, ownProps) => {
  return {
    title: state.dictionary.facetsTitle,
    facets: state.facets,
    selectedFacets: allSelectedFacets(state.facets),
    numOpenGroups: state.numOpenGroups,
    mobileFacetsShown: state.mobileFacetsShown,
    dictionary: state.dictionary,
    eventSpan: state.query.eventSpan
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleFacetChange: (uniqueId, facetValue) => {
      dispatch(loadFacetChange(uniqueId, facetValue));
    },

    handleClearGroup: (group) => {
      dispatch(loadClearFacetGroup(group));
    },

    handleClearAll: () => {
      dispatch(loadClearAllFacets());
    },

    handleSelectGroup: (group) => {
      dispatch(loadSelectFacetGroup(group))
    },
    hideMobileFacets: () => {
      dispatch(hideMobileFacets);
    },
    loadDateRangeChange: (range) => {
    dispatch(loadDateRangeChange(range));
  },
  loadEventDateRangeChange: (range) => {
    dispatch(loadEventDateRangeChange(range));
  },
  loadEventSpanChange: (string) => {
    dispatch(loadEventSpanChange(string));
  }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FacetList);
