import React from "react";

const SearchStatus = ({
  loading,
  resultsRange,
  removeEventSpan,
  totalResults,
  keywords,
  selectedFacets,
  handleFacetDismiss,
  eventSpan,
  removeEventDate
}) => {
  const shouldRenderFacets = selectedFacets.length > 0 || eventSpan;
  const shouldRenderKeywords = !!keywords;
  const shouldRenderResultStatus = !loading;

  const onFacetClick = value => {
    handleFacetDismiss(value.uniqueId);
  };

  const resultLabel = totalResults === 1 ? "result" : "results";

  const renderFakeFacets = (fakeFacet) => {
    if (fakeFacet) {
      return (
        <li key={"EventDuration--" + eventSpan}>
          <span>{eventSpan} Events</span>
          <button
            onClick={() => {
              removeEventSpan("EventDuration--" + eventSpan);
              removeEventDate("");
            }}
          >
            <svg className="icon">
              <use xlinkHref="/assets/ACHE/img/svg-sprite.svg#close" />
            </svg>
          </button>
        </li>
      );
    }
  };
  const renderSelectedFacets = () => {
    return (
      <div className="search-results__active-facets">
        <ul>
          {selectedFacets.map(value => {
            return (
              <li key={value.uniqueId}>
                <span>{value.name} </span>
                <button onClick={() => onFacetClick(value)}>
                  <svg className="icon">
                    <use xlinkHref="/assets/ACHE/img/svg-sprite.svg#close" />
                  </svg>
                </button>
              </li>
            );
          })
          }
          {renderFakeFacets(eventSpan)}
        </ul>
      </div>
    );
  };
  return (
    <span>
      {shouldRenderResultStatus &&
        <div className="search-results__status">
          <strong>{resultsRange}</strong>
          &nbsp;
          <span>of</span>
          &nbsp;
          <strong>{totalResults}</strong>
          &nbsp;
          <span>{resultLabel}</span>{" "}
          {shouldRenderKeywords && (
            <span>
              for the keyword: &quot;
              {keywords}
              &quot;&nbsp;
            </span>
          )}
          {shouldRenderFacets ? <span>within:</span> : null}
          {shouldRenderFacets && renderSelectedFacets()}
        </div>
      }
    </span>
  );
};

const { string, number, object, arrayOf, func } = React.PropTypes;

SearchStatus.propTypes = {
  resultsRange: string.isRequired,
  totalResults: number.isRequired,
  keywords: string,
  eventSpan: string,
  selectedFacets: arrayOf(object).isRequired,
  handleFacetDismiss: func.isRequired
};

SearchStatus.defaultProps = {
  selectedFacets: []
};

export default SearchStatus;
