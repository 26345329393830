import $ from "jquery";

export class WffmHacks {
// js-wffm__checkbox
  constructor($el) {
    const fakeCheckbox = "<span class=\"js-wffm__checkbox\"></span>";
    const fakeRadio = "<span class=\"js-wffm__radio\"></span>";

    this.$el = $el;
    this.$checkbox = $el.find('input:checkbox');
    this.$radio = $el.find('input:radio');

    this.$checkbox.each(function(){
      $(this).after(fakeCheckbox);
    });

    this.$radio.each(function(){
      $(this).after(fakeRadio);

    });
  }

}
