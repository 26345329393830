import $ from "jquery";

export class SearchToggle {

  constructor($el) {
    this.$el = $el;
    this.$toggle = $el.find('.search-box__button--toggle');
    this.$searchBox = $el.find('.search-box');

    this.bindEvents();
  }

  bindEvents() {
    this.$toggle.click((event) => this.toggleMenu(event));
  }

  toggleMenu(event) {
    event.preventDefault();

    this.$searchBox.toggleClass('is-open');
  }
}
