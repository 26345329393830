import $ from "jquery";
import "./custom-vendor/slick-carousel/slick/slick";

export class PromoSlideshow {
  constructor($el, conf) {
    this.$el = $el;
    let slickConf = Object.assign(this.slickDefaults(), conf);
    // bind event prior to slick init
    //this.bindEvents();
    this.$el.slick(slickConf);
  }

  /*bindEvents() {
    // bind an event on the arrows to de-focus them when
    // you mouse out, so the focus state doesn't get stuck
    this.$el.on('mouseleave', '.slick-next, .slick-prev', event => {
      event.currentTarget.blur();
    });
    this.$el.on("setPosition", (currentSlide, event) => {
      let target = currentSlide.currentTarget;
      this.slickSetTop(target);
    });
    $(window).on("resize", () =>{
      $(".slick-dots").removeAttr("style");
    });
    this.$el.on("init", (slick) => {
      let target = slick.currentTarget;
      this.slickSetTop(target);
    });
  }*/

  slickDefaults() {
    return {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 300,
      slide: ".promo-slideshow__item",
      slidesToShow: 1,
      slidesToScroll: 1,
    };
  }
}
