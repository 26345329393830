import $ from 'jquery';
import "./custom-vendor/slick-carousel/slick/slick";

// @TODO: let people know youre sharing that 1024 breakpoint
export class NewsSlideshow {
  constructor($el, conf) {
    const slickConf = Object.assign(this.slickDefaults(), conf);

    this.$el = $el;
    this.numberOfSlides = 0;
    this.activeslide = 0;

    // bind event prior to slick init
    this.bindEvents();
    this.$el.slick(slickConf);
  }

  bindEvents() {
    // bind an event on the arrows to de-focus them when
    // you mouse out, so the focus state doesn't get stuck
    this.$el.on('mouseleave', '.slick-next, .slick-prev', event => {
      event.currentTarget.blur();
    });

    this.$el.on("beforeChange", event => {
    });

    this.$el.on("afterChange", event => {
      this.controlBackButton();
    });
  }

  controlBackButton() {
    this.activeslide = this.$el.slick('slickCurrentSlide');
    this.$el.addClass("back-button-is-visible");

    // if (this.activeslide != 0) {
    //   this.activeslide = this.$el.slick('slickCurrentSlide');
    // } else {
    //   this.$el.removeClass("back-button-is-visible");
    // }
  }

  slickDefaults() {
    return {
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: `
      <button type="button" class="slick-prev" tabindex="0">
          Previous slide
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
          <path fill="#424242" d="M15.422 16.078l-1.406 1.406-6-6 6-6 1.406 1.406-4.594 4.594z"></path>
        </svg>
       </button>`,
      nextArrow: `
        <button type="button" class="slick-next" tabindex="0">
            Next slide
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
            <path fill="#424242" d="M8.578 16.359l4.594-4.594-4.594-4.594 1.406-1.406 6 6-6 6z"></path>
            </svg>
         </button>`,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1
          }
        }
      ],
    }
  }
}
