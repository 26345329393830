export class UserMenu {

  constructor($el) {
    this.$el = $el;
    this.$list = $el.find('.js-user-menu__list');
    this.$trigger = $el.find('.js-user-menu__trigger');
    this.isOpen = false;

    this.bindEvents();
  }

  bindEvents() {
    this.$trigger.on("mousedown", (event) => this.toggleMenu(event));
    this.$trigger.on("focus", (event) => this.toggleMenu(event));
  }

  toggleMenu(event) {
    event.preventDefault();
    this.isOpen = !this.isOpen;
    this.$el.toggleClass('is-open');
    this.$el.attr('aria-expanded', this.isOpen);
  }
}
