import $ from "jquery";
import R from "ramda";
import React from "react";
import ReactDOM from "react-dom";
import thunk from "redux-thunk";
import { Provider } from "react-redux";

import rootReducer from "./redux/rootReducer";
import { initialState as initialSearchState } from "./redux/search/search.reducer";
import { loadResults } from "./redux/search/search.actions";
import configureStore from "./redux/configureStore";
import createRoutingMiddleware from "./redux/createRoutingMiddleware";
import { mapStateToUrl, handleUrlChange } from "./redux/routing";

import { RESULTS_DISPLAY_APPEND, RESULTS_DISPLAY_REFRESH } from "./redux/resultsDisplay";

import ResultsContainer from "./containers/ResultsContainer.jsx";
import QueryContainer from "./containers/QueryContainer.jsx";
import FacetsContainer from "./containers/FacetsContainer.jsx";

// the mount nodes are the elements we want to include
// as part of the search app
export function initSearchApp(mountNodes) {
	if (!mountNodes.length) {
		return;
	}

	// extract the searchId from the mountNodes
	// while also checking mountNodes share same id
	const searchId = mountNodes.reduce((id, node) => {
		const nodeId = $(node).data("searchId");
		// if id is null, we want to set it
		// with the first node's value
		if (id === null) {
			return nodeId;
		}
		// if node id does not equal current
		// id value, it's invalid and return undefined
		if (id !== nodeId) {
			return;
		}

		return id;
	}, null);

	// if we have an invalid or non-matching id, raise an error
	if (!searchId) {
		throw new Error(
			"Could not initialize ACHE search app. searchId was either not present, or provided mount nodes did not share the same searchId"
		);
	}

	// define the canonical inital state of our redux store here
	// including some value taken from the data-attrs of the
	// mount node.
	const config = window.ACHESearchConfigs.find((c) => c.searchId === searchId);
	if (!config) {
		throw new Error(
			`Could not initialize ACHE search app instance with id of "${searchId}". Configuration data not found.`
		);
	}

	// create a starting state object,
	// which includes and intitial state
	// from individual reducers
	let initialState = R.merge(initialSearchState, {
		url: config.url,
		pagination: config.pagination,
		resultsDisplayStrategy: config.pagination ? RESULTS_DISPLAY_REFRESH : RESULTS_DISPLAY_APPEND,
		sorters: config.sorters,
		dictionary: config.dictionary,
		numOpenGroups: config.numOpenGroups,
		query: R.merge(initialSearchState.query, {
			pageId: config.pageId,
		}),
	});

	// determine if we want to load all page results in our cold load
	const doLoadAllPages = initialState.resultsDisplayStrategy === RESULTS_DISPLAY_APPEND && initialState.isFirstLoad;

	initialState = R.merge(initialState, {
		query: R.merge(initialState.query, {
			loadAllPages: doLoadAllPages,
		}),
	});

	const routingMiddleware = createRoutingMiddleware(mapStateToUrl, handleUrlChange);
	const store = configureStore(rootReducer, initialState, [thunk, routingMiddleware]);

	store.dispatch(loadResults());

	mountNodes.forEach((node) => {
		if (node.className.indexOf("js-search-query") > -1) {
			ReactDOM.render(
				<Provider store={store}>
					<QueryContainer />
				</Provider>,
				node
			);
		}

		if (node.className.indexOf("js-search-results") > -1) {
			ReactDOM.render(
				<Provider store={store}>
					<ResultsContainer />
				</Provider>,
				node
			);
		}

		if (node.className.indexOf("js-search-facets") > -1) {
			ReactDOM.render(
				<Provider store={store}>
					<FacetsContainer />
				</Provider>,
				node
			);
		}
	});
}

$(document).ready(function () {
	if ($(".bookstore-search-filters").length > 0) {
		function clickOnItem() {
			$("#filtersPagination").on("click", "li", function () {
				var topics = [];
				var collections = [];
				var singleTopic = $("input[name='topics']:checked");
				var singleCollection = $("input[name='collections']:checked");
				$.each($(singleTopic), function () {
					topics.push($(this).val());
				});
				$.each($(singleCollection), function () {
					collections.push($(this).val());
				});
				if(singleTopic.length>0 || singleCollection.length>0)
				{
					$(".clear-filters").removeClass("none-selected");
				}
				if(singleTopic.length==0 && singleCollection.length==0)
				{
					$(".clear-filters").addClass("none-selected");
				}
				
				$("#filtersPagination li.active").removeClass("active");
				$(this).addClass("active");
				var pageActive = $(this).text();
				var pageActiveInt = parseInt(pageActive);

				$.ajax({
					data: {
						topic: topics.join(","),
						collection: collections.join(","),
						page: pageActiveInt,
					},
					url: "/api/sitecore/search/bookstorelist/",
					success: function (result) {
						$("#bookResults").html(result);
						clickOnPrev();
						clickOnItem();
						clickOnNext();
					},
				});
			});
		}

		function clickOnPrev() {
			var paginationPrevButton = $(".bookstore-search-filters__pagination-prev");
			$(paginationPrevButton).click(function () {
				var currentActivePage = $("#filtersPagination li.active");
				$(currentActivePage).removeClass("active");
				$(currentActivePage).prev().addClass("active");
				var activePageNumber = $(currentActivePage).text();
				var activePageNumberInt = parseInt(activePageNumber);
				var previousPageActive = activePageNumberInt - 1;

				var topics = [];
				var collections = [];
				var singleTopic = $("input[name='topics']:checked");
				var singleCollection = $("input[name='collections']:checked");
				$.each($(singleTopic), function () {
					topics.push($(this).val());
				});
				$.each($(singleCollection), function () {
					collections.push($(this).val());
				});

				// ajax call for click on pagination previous button
				$.ajax({
					data: {
						topic: topics.join(","),
						collection: collections.join(","),
						page: previousPageActive,
					},
					url: "/api/sitecore/search/bookstorelist/",
					success: function (result) {
						$("#bookResults").html(result);
						clickOnPrev();
						clickOnItem();
						clickOnNext();
					},
				});
			});
		}

		function clickOnNext() {
			var paginationNextButton = $(".bookstore-search-filters__pagination-next");
			$(paginationNextButton).click(function () {
				var currentActivePage = $("#filtersPagination li.active");
				$(currentActivePage).removeClass("active");
				$(currentActivePage).next().addClass("active");
				var activePageNumber = $(currentActivePage).text();
				var activePageNumberInt = parseInt(activePageNumber);
				var nextPageActive = activePageNumberInt + 1;

				var topics = [];
				var collections = [];
				var singleTopic = $("input[name='topics']:checked");
				var singleCollection = $("input[name='collections']:checked");
				$.each($(singleTopic), function () {
					topics.push($(this).val());
				});
				$.each($(singleCollection), function () {
					collections.push($(this).val());
				});

				// ajax call for click on pagination next button
				$.ajax({
					data: {
						topic: topics.join(","),
						collection: collections.join(","),
						page: nextPageActive,
					},
					url: "/api/sitecore/search/bookstorelist/",
					success: function (result) {
						$("#bookResults").html(result);
						clickOnPrev();
						clickOnItem();
						clickOnNext();
					},
				});
			});
		}

		//ajax call for click on checkbox
		$('input[type="checkbox"]').click(function () {
			var topics = [];
			var collections = [];
			var singleTopic = $("input[name='topics']:checked");
			var singleCollection = $("input[name='collections']:checked");

			$.each($(singleTopic), function () {
				topics.push($(this).val());
				//  $(".selected-filters").html(topics.join("<br>"));
			});
			$.each($(singleCollection), function () {
				collections.push($(this).val());
				//  $(".selected-filters").html(collections.join("<br>"));
			});
			
			if(singleTopic.length>0 || singleCollection.length>0)
			{
				$(".clear-filters").removeClass("none-selected");
			}
			if(singleTopic.length==0 && singleCollection.length==0)
			{
				$(".clear-filters").addClass("none-selected");
			}
			$("#filtersPagination li").removeClass("active");
			$("#filtersPagination li:first").addClass("active");

			$.ajax({
				data: {
					topic: topics.join(","),
					collection: collections.join(","),
					page: 1,
				},
				url: "/api/sitecore/search/bookstorelist/",
				success: function (result) {
					$("#bookResults").html(result);
					clickOnPrev();
					clickOnItem();
					clickOnNext();
				},
			});
		});

		//ajax call for click on clear filters buttons
		var clearFiltersButton = $(".clear-filters");
		$(clearFiltersButton).click(function () {
			var selectedFilters = $(".selected-filters");
			var singleTopic = $("input[name='topics']:checked");
			var singleCollection = $("input[name='collections']:checked");
			$(".clear-filters").addClass("none-selected");
			
			$(selectedFilters).hide();
			$(singleTopic).prop("checked", false);
			$(singleCollection).prop("checked", false);
			$("#filtersPagination li").removeClass("active");
			$("#filtersPagination li:first").addClass("active");
			$.ajax({
				data: {
					topic: "",
					collection: "",
					page: 1,
				},
				url: "/api/sitecore/search/bookstorelist/",
				success: function (result) {
					$("#bookResults").html(result);
					clickOnPrev();
					clickOnItem();
					clickOnNext();
				},
			});
		});

		//pagination
		if ($(".bookstore-search-filters__pagination").length > 0) {
			var paginationPrevButton = $(".bookstore-search-filters__pagination-prev");
			var paginationNextButton = $(".bookstore-search-filters__pagination-next");

			$("#filtersPagination").on("click", "li", function () {
				var topics = [];
				var collections = [];
				var singleTopic = $("input[name='topics']:checked");
				var singleCollection = $("input[name='collections']:checked");
				$.each($(singleTopic), function () {
					topics.push($(this).val());
				});
				$.each($(singleCollection), function () {
					collections.push($(this).val());
				});
				$("#filtersPagination li.active").removeClass("active");
				$(this).addClass("active");
				var pageActive = $(this).text();
				var pageActiveInt = parseInt(pageActive);

				$.ajax({
					data: {
						topic: topics.join(","),
						collection: collections.join(","),
						page: pageActiveInt,
					},
					url: "/api/sitecore/search/bookstorelist/",
					success: function (result) {
						$("#bookResults").html(result);
						clickOnPrev();
						clickOnItem();
						clickOnNext();
					},
				});
			});

			$(paginationPrevButton).click(function () {
				var currentActivePage = $("#filtersPagination li.active");
				$(currentActivePage).removeClass("active");
				$(currentActivePage).prev().addClass("active");
				var activePageNumber = $(currentActivePage).text();
				var activePageNumberInt = parseInt(activePageNumber);
				var previousPageActive = activePageNumberInt - 1;

				var topics = [];
				var collections = [];
				var singleTopic = $("input[name='topics']:checked");
				var singleCollection = $("input[name='collections']:checked");
				$.each($(singleTopic), function () {
					topics.push($(this).val());
				});
				$.each($(singleCollection), function () {
					collections.push($(this).val());
				});

				// ajax call for click on pagination previous button
				$.ajax({
					data: {
						topic: topics.join(","),
						collection: collections.join(","),
						page: previousPageActive,
					},
					url: "/api/sitecore/search/bookstorelist/",
					success: function (result) {
						$("#bookResults").html(result);
						clickOnPrev();
						clickOnItem();
						clickOnNext();
					},
				});
			});

			$(paginationNextButton).click(function () {
				var currentActivePage = $("#filtersPagination li.active");
				$(currentActivePage).removeClass("active");
				$(currentActivePage).next().addClass("active");
				var activePageNumber = $(currentActivePage).text();
				var activePageNumberInt = parseInt(activePageNumber);
				var nextPageActive = activePageNumberInt + 1;

				var topics = [];
				var collections = [];
				var singleTopic = $("input[name='topics']:checked");
				var singleCollection = $("input[name='collections']:checked");
				$.each($(singleTopic), function () {
					topics.push($(this).val());
				});
				$.each($(singleCollection), function () {
					collections.push($(this).val());
				});

				// ajax call for click on pagination next button
				$.ajax({
					data: {
						topic: topics.join(","),
						collection: collections.join(","),
						page: nextPageActive,
					},
					url: "/api/sitecore/search/bookstorelist/",
					success: function (result) {
						$("#bookResults").html(result);
						clickOnPrev();
						clickOnItem();
						clickOnNext();
					},
				});
			});
		}
	}
});
