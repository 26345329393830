import $ from "jquery";

export class BookDetailPdf {
  constructor($el) {
    this.$el = $el;
    this.$toggle = $el.find(".js-microsite-nav__show-more");
    let numPdfs = $el.find("li").length;
    this.bindEvents();
    if (numPdfs > 5) {
      this.$toggle.css({"visibility": "visible", "margin-top": "40px"});
    }
  }

  bindEvents() {
    this.$toggle.click((event) => this.toggleMenu(event));
  }

  toggleMenu(event) {
    event.preventDefault();
    let $ul = $($(event.currentTarget).prev());
    let $hiddenLi = $ul.find("li:nth-child(n+6)")
    $hiddenLi.slideToggle();
    this.$toggle.toggleClass("is-open");
  }
}
