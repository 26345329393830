import React from "react";
import moment from "moment";
import R from "ramda";

export default class ResultItem extends React.Component {
  constructor(props) {
    super(props);
    this.itemWrapper = null;
  }

  componentDidMount() {
    this.itemWrapper.className = this.itemWrapper.className + " is-visible";
  }

  formatDate(dateStr) {
    const date = new Date(dateStr);
    return moment(date).format("MMMM YYYY");
  }
  serviceItem(serviceContacts, phoneLabel, emailLabel) {
    let serviceList = serviceContacts.map(function(serviceItem, i) {
      return (
        <div key={i} className="service-item__sub-group">
          <h3 className="service-item__sub-group__title">
            {serviceItem.subGroupTitle}
          </h3>
          <ul
            className={
              serviceItem.subDepartments.length > 1
                ? "service-item__sub-department service-item__sub-department--columns"
                : "service-item__sub-department"
            }
          >
            {serviceItem.subDepartments.map(function(department, i) {
              return (
                <li key={`${department.subDeptLink}-${i}`}>
                  <h4 className="service-item__sub-department__title">
                    {department.subDeptTitle}
                  </h4>
                  <span className="service-item__sub-department__meta">
                    <a href={department.subDeptLink}>
                      {department.subDeptName}
                    </a>
                  </span>
                  <span className="service-item__sub-department__meta">
                    {phoneLabel}:
                    <a href={"tel:" + department.subDeptPhone}>
                      {department.subDeptPhone}
                    </a>
                  </span>
                  <span className="service-item__sub-department__meta">
                    {emailLabel}:
                    <a href={"mailto:" + department.subDeptEmail}>
                      {department.subDeptEmail}
                    </a>
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      );
    });
    return serviceList;
  }

  bodySubString(body) {
    var cleanTxt = body.replace(/<[^>]+>/g, "");
    return cleanTxt.substring(0, 301) + "... ";
  }
  render() {
    const {
      authors,
      body,
      contentType,
      contentUrl,
      date,
      displayDate,
      description,
      departmentDescription,
      departmentTitle,
      displayUrl,
      emailLabel,
      fullName,
      imageSrc,
      name,
      phoneLabel,
      primaryBio,
      primaryBioRole,
      primaryTermEnd,
      subGroups,
      title,
      type,
      url
    } = this.props;
    const getConfig = () => {
      const defaultConfig = {
        body: body && this.bodySubString(body),
        contentType: contentType,
        date: null,
        displayUrl: displayUrl,
        location: null,
        photo: null,
        // photo: 'http://via.placeholder.com/125x175',
        preferredFullName: null,
        termEnd: null,
        serviceContacts: null, //assume this will be an array
        title: title,
        type: null,
        isEventSession: null,
        displayDate: displayDate || (date && this.formatDate(date)) //will presumably grab from a different field depending on card type.
      };

      if (contentType == "Author") {
        return R.merge(defaultConfig, {
          photo: imageSrc,
          displayDate: null,
          title: fullName,
          body: primaryBio && this.bodySubString(primaryBio),
          contentType: primaryBioRole,
          termEnd: primaryTermEnd
        });
      } else if (contentType == "Service Department") {
        return R.merge(defaultConfig, {
          serviceContacts: subGroups,
          contentType: null,
          body: departmentDescription && this.bodySubString(departmentDescription),
          title: departmentTitle
        });
      } else if (contentType == "Sessions") {
        return R.merge(defaultConfig, {
          body: description && this.bodySubString(description),
          type: type,
          isEventSession: true
        });
      } else if (contentType == "Event") {
        return R.merge(defaultConfig, {
          body: description && this.bodySubString(description),
          type: type,
          isEventSession: true
        });
      } else {
        return defaultConfig;
      }
    };
    const config = getConfig();

    return (
      <article className="search-item" ref={el => (this.itemWrapper = el)}>
        <div className="search-item__category">
          {contentType}
        </div>
        <h3 className="search-item__title">
          <a href={config.displayUrl}>
            {config.title}
            {config.termEnd ? "/" + config.termEnd : null}
          </a>
        </h3>
        <div className="search-item__meta">
          {config.displayDate ? (
            <span className="search-item__date">{config.displayDate}</span>
          ) : null}
        </div>
        {config.photo ? (
          <img
            className="search-item__img"
            src={config.photo}
            alt={config.title}
          />
        ) : null}
        <p className="search-item__body" dangerouslySetInnerHTML={{__html: config.body}}></p>
        {config.serviceContacts ? (
          <div className="service-item">
            {this.serviceItem(config.serviceContacts, phoneLabel, emailLabel)}
          </div>
        ) : null}
      </article>
    );
  }
}

const { arrayOf, string } = React.PropTypes;
ResultItem.propTypes = {
  contentType: string,
  date: string,
  displayDate: string,
  title: string,
  body: string,
  contentUrl: string,
  displayUrl: string
};
