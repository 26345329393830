import $ from "jquery";

const $utilityNav = $(".js-utility-nav__list");
const isPageEditor = ".is-page-editor";
const menuDiv = ".js-user-menu";
$(document).ready(function(){
  if ($utilityNav && $utilityNav.children().length <= 0 && !$(isPageEditor)) {
    $(menuDiv + " .login-form__dropdown-wrapper:before").css("display", "none");
  }
  
})
