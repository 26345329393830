import React from "react";
import FacetGroup from "./FacetGroup.jsx";
import "react-dates/initialize";
import {
  closeFilters,
  setFiltersOpenDirection,
  manageContainerWhitespace
} from "../filterSlider";
import classnames from "classnames";
import URI from "urijs";
import Datepicker from "./Datepicker.jsx";

export default class FacetList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      researchOpenState: null,
      eventOpenState: null
    };
  }

  componentDidMount() {
    setFiltersOpenDirection();
    manageContainerWhitespace();
  }
  clearAll() {
    this.refs["date"].clearDateRange();
    if (this.refs["facetgroups"]) {
      this.refs["facetgroups"].deselectEventDuration();
    }
    this.props.handleClearAll();
    document.querySelector("input").checked = false;
  }
  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (
      nextProps.eventSpan !== this.props.eventSpan &&
      (nextProps.eventSpan == null || nextProps.eventSpan == undefined)
    ) {
      if (this.refs["facetgroups"]) {
        this.refs["facetgroups"].deselectEventDuration();
      }
    }
  }
  hideFilters() {
    closeFilters(); // DOM ops
    this.props.hideMobileFacets(); // action creator
  }

  renderHideFilters() {
    return (
      <div className="search-facets__filter-close">
        <button
          type="button"
          className="btn--primary"
          onClick={() => this.hideFilters()}
        >
          {this.props.title}
          <svg className="icon">
            <use xlinkHref="/assets/ACHE/img/svg-sprite.svg#close" />
          </svg>
        </button>
      </div>
    );
  }
  researchPubs() {
    return ["RPAuthor", "RPContentType"];
  }
  collectionsFacet() {
    return ["collections"];
  }
  eventsAttendanceFacets() {
    return ["EventAttendance"];
  }
  eventsDurationFacets() {
    return ["EventDuration"];
  }
  eventsLocationFacets() {
    return ["locations"];
  }
  eventsTypeFacets() {
    //TODO: Get Event Facets
    return ["EventType"];
  }
  orderFacets() {
    //if we need to totally render manually, we can put the facet ids here in order
    let originalFacets = this.props.facets;
    const groupedFacets = [];
    const sortFacets = ["contentType", "topic"]; //this is where we put the ids in the order we want.
    sortFacets.forEach(function (key) {
      var found = false;
      originalFacets = originalFacets.filter(function (facet) {
        if (!found && facet.id == key) {
          groupedFacets.push(facet);
          found = true;
          return false;
        } else {
          return true;
        }
      });
    });
    console.log(groupedFacets)
    return groupedFacets;
  }
  renderCustomFacetGroups(facetgroups) {
    return this.props.facets.map((group, i) => {
      if (group.values.length === 0 && group.childGroups.length === 0) {
        return null;
      }
      const numOpenGroups = this.props.numOpenGroups;
      const isOpen =
        typeof numOpenGroups !== "undefined" &&
        numOpenGroups !== null &&
        i < numOpenGroups;
      const eventRef = group.id == "EventDuration" ? "facetgroups" : null;
      return facetgroups.includes(group.id) ? (
        <FacetGroup
          key={group.id}
          handleFacetChange={this.props.handleFacetChange}
          // handleClearGroup={this.props.handleClearGroup}
          handleDateChange={this.props.loadEventDateRangeChange}
          handleSelectGroup={this.props.handleSelectGroup}
          handleDateDurationChange={this.props.loadEventSpanChange}
          isOpen={isOpen}
          group={group}
          ref={eventRef}
          eventSpan={this.props.eventSpan}
          dictionary={this.props.dictionary}
        />
      ) : null;
    });
  }
  renderFacetGroups() {
    const excludedGroup = this.researchPubs();
    excludedGroup.push(
      "collections",
      "EventDuration",
      "EventType",
      "EventAttendance",
      "locations"
    );

    let facetArray = [];
    this.props.facets.forEach((facet, i) => {
      // pushes contentType to the beginning of the array so it renders at the top
      if (facet.id == "contentType") {
        facetArray.unshift(facet);
      }
      if (facet.id == "topic") {
        facetArray.push(facet);
      }
    })

    return facetArray.map((group, i) => {
      if (group.values.length === 0 && group.childGroups.length === 0) {
        return null;
      }

      const numOpenGroups = this.props.numOpenGroups;
      const isOpen = true;
      return !excludedGroup.includes(group.id) ? (
        <FacetGroup
          key={group.id}
          handleFacetChange={this.props.handleFacetChange}
          // handleClearGroup={this.props.handleClearGroup}
          handleDateChange={this.props.loadEventDateRangeChange}
          handleDateDurationChange={this.props.loadEventSpanChange}
          isOpen={isOpen}
          group={group}
          dictionary={this.props.dictionary}
        />
      ) : null;
    });
  }

  renderClearAll(selectedFacets) {
    const renderedSelectedFacets = selectedFacets.map(facet =>
      <span className="search-facets__selected-filter">
        <span>{facet.name} </span>
        <button
          className="button"
          onClick={() => this.props.handleFacetChange(facet.uniqueId, facet)}>
          <svg className="facet-icon">
            <use xlinkHref="/assets/ACHE/img/svg-sprite.svg#close" />
          </svg>
        </button>
      </span>
    );

    const facetLabel = this.props.dictionary.facetsTitle
      ? this.props.dictionary.facetsTitle
      : "Refine";

    return (
      <div className="search-facets__header">
        <div className="search-facets__clear-all">
          <span className="search-facet__refine-label">Filters</span>
          <button className={selectedFacets.length ? "selected" : "none-selected"} type="button" onClick={() => this.clearAll()}>
            Clear Filters
          </button>
        </div>
        <div className="selected-filters">{renderedSelectedFacets}</div>
      </div>
    );
  }

  toggleGroup() {
    const isOpen = !this.state.isGroupOpen;
    this.setState({ isGroupOpen: isOpen });
  }

  toggleEventGroup() {
    const isOpen = !this.state.eventOpenState;
    this.setState({ eventOpenState: isOpen });
  }
  togglePublicationGroup() {
    const isOpen = !this.state.researchOpenState;
    this.setState({ researchOpenState: isOpen });
  }
  render() {
    const facetGroups = this.renderFacetGroups();
    const researchFacets = this.researchPubs();
    const selectedFacets = this.props.selectedFacets;
    const collectionsFacet = this.collectionsFacet();
    const eventsAttendanceFacets = this.eventsAttendanceFacets();
    const eventsTypeFacets = this.eventsTypeFacets();
    const eventsDurationFacets = this.eventsDurationFacets();
    const eventsLocationFacets = this.eventsLocationFacets();
    const researchPubFacetGroups = this.renderCustomFacetGroups(researchFacets);
    const eventsTypeFacetsGroup = this.renderCustomFacetGroups(
      eventsTypeFacets
    );
    const eventsDurationGroup = this.renderCustomFacetGroups(
      eventsDurationFacets
    );
    const eventsLocationGroup = this.renderCustomFacetGroups(
      eventsLocationFacets
    );

    const eventsAttendanceFacetsGroup = this.renderCustomFacetGroups(
      eventsAttendanceFacets
    );

    const openEventClass = classnames("search-facets__group-toggle", {
      "is-open": this.state.eventOpenState
    });
    const openPublicationClass = classnames("search-facets__group-toggle", {
      "is-open": this.state.researchOpenState
    });

    const publicationGroupClass = classnames(
      "search-facets__group search-facets__group--publications",
      { "is-open": this.state.researchOpenState }
    );
    const eventGroupClass = classnames(
      "search-facets__group search-facets__group--events",
      { "is-open": this.state.eventOpenState }
    );

    const researchPubFacetTitle = this.props.dictionary.researchPubFacetTitle
      ? this.props.dictionary.researchPubFacetTitle
      : "By Research & Publication Type";

    const eventsFacetTitle = this.props.dictionary.eventEducationFacetTitle
      ? this.props.dictionary.eventEducationFacetTitle
      : "Education & Events";
    return (
      <div>
        {this.renderHideFilters()}
        {this.props.mobileFacetsShown ? <h3>"{this.props.title}"</h3> : null}
        <div className="search-facets">
          {this.renderClearAll(selectedFacets)}
          {facetGroups}

          <div className={eventGroupClass}>
            <div className="search-facets__group-heading">
              <button
                className={openEventClass}
                type="button"
                onClick={e => this.toggleEventGroup(e)}
              >
                <svg className="icon">
                  <use xlinkHref="/assets/ACHE/img/svg-sprite.svg#chevron-right" />
                </svg>
              </button>
              <h4>{eventsFacetTitle}</h4>
            </div>
            <div className="search-facets__group-wrapper">
              {eventsAttendanceFacetsGroup}
              {eventsDurationGroup}
              {eventsLocationGroup}
              {eventsTypeFacetsGroup}
            </div>
          </div>

          <div className={publicationGroupClass}>
            <div className="search-facets__group-heading">
              <button
                className={openPublicationClass}
                type="button"
                onClick={e => this.togglePublicationGroup(e)}
              >
                <svg className="icon">
                  <use xlinkHref="/assets/ACHE/img/svg-sprite.svg#chevron-right" />
                </svg>
              </button>
              <h4>{researchPubFacetTitle}</h4>
            </div>
            <div className="search-facets__group-wrapper">
              {researchPubFacetGroups}

              <Datepicker
                handleDateChange={this.props.loadDateRangeChange}
                ref="date"
              />
            </div>
          </div>
          {/* {renderCollections} */}
        </div>
      </div>
    );
  }
}

const {
  string,
  object,
  arrayOf,
  bool,
  shape,
  number,
  oneOfType,
  func
} = React.PropTypes;
FacetList.propTypes = {
  title: string,
  mobileFiltersShown: bool,
  hideMobileFacets: func,
  facets: arrayOf(
    shape({
      id: oneOfType([number, string])
    })
  ),
  selectedFacets: arrayOf(object).isRequired,
  numOpenGroups: number,
  handleFacetChange: func.isRequired,
  // handleClearGroup: func.isRequired,
  handleSelectGroup: func.isRequired,
  handleClearAll: func.isRequired
};

FacetList.defaultProps = {
  facets: [],
  selectedFacets: [],
  numOpenGroups: null
};
