import $ from "jquery";
const $isExperienceEditor = $(".is-page-editor");
export default function initializeTables() {
  if($isExperienceEditor.length == 0) {
    $(".rich-text table").each(function() {
      let $headerRow = {};
      let $otherRows = [];

      $(this).addClass("table-responsive");

      $headerRow = $(this).find("tr:first-child");
      $otherRows = $(this).find("tr").not(":eq(0)");

      let headerLabels = [];

      $headerRow.find("th, td").each(function() {
        headerLabels.push($(this).text().trim());
      });

      $otherRows.each(function() {
        $(this).find("td").each(function(i) {
          if (headerLabels[i] !== undefined) {
            $(this).wrapInner("<span class='table-responsive__mobile-text'>");
            $(this).prepend("<span class='table-responsive__mobile-label'>" + headerLabels[i] + "</span>");
          }
        });
      });
    });
  }
}
