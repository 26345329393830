import $ from "jquery";

const showMoreBooksButton = $(".showMoreBtn");
const hiddenBooksWrapper = $(".showMoreBooks");

$(document).ready(function(){
  if (showMoreBooksButton.length > 0) {
    $(showMoreBooksButton).click(function() {
      $(hiddenBooksWrapper).show();
      $(showMoreBooksButton).hide();
    });
  }
});