import $ from "jquery";

export class Ads {
  constructor($el) {
    this.$el = $el;
    this.$closeTrigger = $el.find(".js-ad-close");

    this.bindEvents();
  }

  bindEvents() {
    this.$closeTrigger.click((e) => this.closeAd(e));

    if (this.$el.hasClass("js-ad-interstitial")) {
      $(".js-ad-interstitial").click((e) => this.handleInterstitialClick(e));
    }
  }

  closeAd(event) {
    event.preventDefault();

    this.$el.addClass("u-hidden");
  }

  handleInterstitialClick(e) {
    // click anything but the anchor, close the modal
    if (!$(e.target).closest("a").length) {
      this.$el.addClass("u-hidden");
    }
  }
}
