module.exports={
  "links": [
    {"displayText": "Virtual Leadership Symposium", "url": "https://www.ache.org/learning-center/education-and-events/virtual-leadership-symposium", "keyWords": "virtual leadership symposium"},
    {"displayText": "Chapters", "url": "https://www.ache.org/CHAPTERS", "keyWords": "chapters"},
    {"displayText": "Albert W. Dent Graduate Student Scholarship", "url": "https://www.ache.org/membership/student-resources/albert-w-dent-graduate-student-scholarship", "keyWords": "albert dent graduate student scholarships"},
    {"displayText": "Foster G. McGaw Graduate Student Scholarship", "url": "https://www.ache.org/membership/student-resources/foster-g-mcgaw-graduate-student-scholarship", "keyWords": "foster mcgaw graduate student scholarships"},
    {"displayText": "Healthcare Consultants Forum", "url": "https://www.ache.org/membership/communities-forums-and-networks/healthcare-consultants-forum", "keyWords": "healthcare consultants medical consultants health care consultant firms"},
    {"displayText": "ACHE Membership Dues", "url": "https://www.ache.org/membership/pay-my-dues/", "keyWords": "pay dues annual dues ache dues membership dues"},
    {"displayText": "Job Center", "url": "https://www.ache.org/career-resource-center/job-center", "keyWords": "ache jobs job center jobcenter healthcare executive jobs near me job listings career"},
    {"displayText": "Job Board", "url": "https://www.ache.org/career-resource-center/job-center", "keyWords": "crc ache jobs job board healthcare executive jobs near me job listings"},
    {"displayText": "Job Posting Portal (Employers)", "url": "https://careers.ache.org/employers/", "keyWords": "employers crc ache jobs job board healthcare executive job posting search resumes"},
    {"displayText": "Career EDGE", "url": "https://www.ache.org/career-resource-center/advance-your-career/career-edge", "keyWords": "ache jobs career edge login careeredge"},
    {"displayText": "ACHE Ethics Resources", "url": "https://www.ache.org/about-ache/our-story/our-commitments/ethics", "keyWords": "ethical healthcare ethics in healthcare written code of ethics patient confidentiality medical confidentiality privacy"},
    {"displayText": "Top Issues Confronting Hospitals (Research)", "url": "https://www.ache.org/learning-center/research/about-the-field/top-issues-confronting-hospitals", "keyWords": "healthcare issues confronting hospitals issues in america research problems in healthcare current issues in healthcare"},
    {"displayText": "ACHE Policy Statements", "url": "https://www.ache.org/about-ache/our-story/our-commitments/policy-statements", "keyWords": "ache policy statements access appropriate ethical responsibility role agreements decisions impaired donation behavior opportunity opportunities"},
    {"displayText": "Membership", "url": "https://www.ache.org/membership", "keyWords": "membership join become a member"},
    {"displayText": "My Online Learning Portal", "url": "https://www.pathlms.com/ache", "keyWords": "ache learning management lms webinars e-learning my online learning board of governors online tutorial my events calendar courses schedule"},
    {"displayText": "Student and Early Careerist Resources", "url": "https://www.ache.org/career-resource-center/special-groups/resources-for-students-and-early-careerists", "keyWords": "students early careerists resources healthcare management degree scholarships internships fellowships support carreeredge interviews networking"},
    {"displayText": "Resume Reviewer Service", "url": "https://www.ache.org/career-resource-center/products-and-services/resume-and-interview/resume-review-critique-service", "keyWords": "resume reviewer crc interviews"},
    {"displayText": "Postgraduate Fellowships", "url": "https://www.ache.org/career-resource-center/seek-new-opportunities/postgraduate-fellowships", "keyWords": "postgraduate fellowships post graduate fellowships administrative fellowships listings"},
    {"displayText": "Physician Leadership", "url": "https://www.ache.org/membership/physician-leader", "keyWords": "membership physician leadership join physician executives community"},
    {"displayText": "Baldrige Program", "url": "https://www.ache.org/about-ache/our-story/our-commitments/baldrige", "keyWords": "baldrige leadership model baldrige model white paper baldrige award balridge"},
    {"displayText": "Healthcare Management Career Overview", "url": "https://www.ache.org/career-resource-center/special-groups/resources-for-students-and-early-careerists/healthcare-management-career-overview", "keyWords": "healthcare management salary entry-level entry level jobs career path"},
    {"displayText": "My Events", "url": "https://account.ache.org/eweb/DynamicPage.aspx?Webcode=MyEvents", "keyWords": "my events"},
    {"displayText": "Executive Search Firms", "url": "https://www.ache.org/career-resource-center/seek-new-opportunities/job-search-and-career-transition-resources/executive-search-firms", "keyWords": "executive search firms list fache hiring"},
    {"displayText": "Higher Education Network", "url": "https://www.ache.org/membership/communities-forums-and-networks/higher-education-network", "keyWords": "higher education network"},
    {"displayText": "LGBTQ+ Healthcare Leaders Community", "url": "https://www.ache.org/membership/communities-forums-and-networks/lgbt-community", "keyWords": "lgbtqia+ lgbtq healthcare leaders community queer leadership"},
    {"displayText": "Asian Healthcare Leaders Community", "url": "https://www.ache.org/membership/communities-forums-and-networks/asian-healthcare-leaders-community", "keyWords": "asian healthcare leaders community leadership"},
    {"displayText": "Dolan Diversity Programs", "url": "https://www.ache.org/about-ache/the-fund/dolan-diversity-programs", "keyWords": "cap thomas c dolan diversity programs the fund for healthcare leadership executive diversity career accelerator donate donation scholarship"},
    {"displayText": "Mentor Network", "url": "https://www.ache.org/career-resource-center/advance-your-career/leadership-mentoring-network", "keyWords": "mentorship mentoring leadership mentor network"},
    {"displayText": "About Health Administration Press (HAP)", "url": "https://www.ache.org/learning-center/publications/books/book-resources/about-hap", "keyWords": "health administration press what is hap books"},
    {"displayText": "Retired or Life Member Status", "url": "https://www.ache.org/membership/why-join-ache/retired-status-or-life-status", "keyWords": "retired lifetime member"},
    {"displayText": "Member Directory", "url": "https://www.ache.org/membership/member-directory", "keyWords": "member directory search members find members"},
    {"displayText": "Webinars", "url": "https://www.ache.org/learning-center/education-and-events/e-learning/webinars", "keyWords": "webinars online learning"}

      
  ],
  "defaults": [
    {"displayText": "Congress", "url": "https://congress.ache.org/", "keyWords": "congress conference annual meeting"},
    {"displayText": "Bookstore", "url": "https://www.ache.org/learning-center/publications/books", "keyWords": "bookstore books textbooks press administration hap"},
    {"displayText": "Earn My FACHE", "url": "https://www.ache.org/fache", "keyWords": "fellowship what is fache certification fache credential about fache degree"}  ]
}