import {connect} from "react-redux";
import ResultList from "../components/ResultList.jsx";
import { loadNextPage, loadNewSort, loadNewPage, loadFacetChange, showMobileFacets,changeEventDateRange, changeEventSpan  } from "../redux/search/search.actions";
import { allSelectedFacets } from "../redux/facets";

const mapStateToProps = (state) => {
  return {
    results: state.results,
    resultsDisplayStrategy: state.resultsDisplayStrategy,
    selectedFacets: allSelectedFacets(state.facets),
    totalResults: state.totalResults,
    loading: state.loading,
    isFirstLoad: state.isFirstLoad,
    sorters: state.sorters,
    query: state.query,
    pagination: state.pagination,
    dictionary: state.dictionary,
    eventSpan: state.query.eventSpan
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    nextPage: () => {
      dispatch(loadNextPage());
    },
    changeSort: (sorters) => {
      dispatch(loadNewSort(sorters));
    },
    gotoPage: (pageNum) => {
      dispatch(loadNewPage(pageNum));
    },
    dismissFacet: (facetId) => {
      dispatch(loadFacetChange(facetId, false));
    },
    showMobileFacets: () => {
      dispatch(showMobileFacets());
    },
    removeEventSpan: () => {
      dispatch(changeEventSpan());
    },
    removeEventDate: () => {
      dispatch(changeEventDateRange());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultList);
