import $ from "jquery";

const $tabContainer = $(".js-tabs");
const $tabs = $(".js-tab");
const $tabContent = $(".js-tab-content");
const agenda = ".js-agenda__tabs";
const isPageEditor = ".is-page-editor";
if ($tabContainer.length && !$(isPageEditor) == false) {
  init();
}

function init() {
  openInitialTab();
  bindEvents();
  if ($(agenda).length) {
    tabAlignment($tabs);
  }
}

function bindEvents() {
  $tabs.on("click", toggleTab);
}

function openInitialTab() {
  $tabs.first().addClass("is-active");
  $tabContent.first().addClass("is-visible");
}

function toggleTab(event) {
  event.preventDefault();
  const windowWidth = $(window).width();
  const $activeTab = $(this);
  const $activeIndex = $tabs.index($activeTab);
  const $activeContent = $tabContent.eq($activeIndex);

  $tabs.removeClass("is-active");
  $activeTab.addClass("is-active");

  $tabContent.removeClass("is-visible");
  $activeContent.addClass("is-visible");

  if (windowWidth <= 720) {
    $tabContainer.toggleClass("is-open");
  }
}

//this is goofy fix for agenda tabs if there are ever less than four they should had different css.
function tabAlignment(tabs) {
  const tabCount = tabs.length;
  if (tabCount < 4) {
    tabs.closest(".tabs__navigation").css("justify-content", "flex-start");
    tabs.each(function() {
      $(this).css("margin-right", "20px");
    });
  }
}
