import $ from "jquery";
import R from "ramda";
import { RESULTS_DISPLAY_APPEND, RESULTS_DISPLAY_REFRESH } from "./resultsDisplay";

const newResults = R.curry((newResults, oldResults, strategy) => {
  return strategy === RESULTS_DISPLAY_APPEND
    ? R.concat(oldResults, newResults)
    : newResults;
});

export function fetchSearchResults(url, query) {
    return $.ajax({
        url: url,
        data: query
    });
}

export function mapResponseToState(response, state) {
  const request = response.request;
  const castToInt = R.curry(parseInt)(R.__, 10);
  return R.evolve({
      totalResults: () => parseInt(response.totalResults, 10),
      results: newResults(response.results, R.__, state.resultsDisplayStrategy),
      facets: () => addUniqueFacetId(response.facets),
      query: R.merge(R.__, {
          pageId: request.pageId,
          page: castToInt(request.page),
          perPage: castToInt(request.perPage),
          sortOrder: request.sortOrder,
          loadAllPages: false,
          eventDate: request.queryParameters.eventDate,
          date: request.queryParameters.date

      })
  }, state);
}



// Some id's coming from the backend are no unique.  ie. group1>blog, group2>blog
function addUniqueFacetId(groups){

  const addUniqueFacetIdToFacet = R.curry((groupId, facet) => {
    return R.merge(facet, {
      uniqueId: `${groupId}-${facet.id}`,
      childValues: facet.childValues.map(addUniqueFacetIdToFacet(groupId))
    });
  });

  const addUniqueFacetIdToGroup = (group) => {
    const values = group.values.map(addUniqueFacetIdToFacet(group.id));
    return R.merge(group, { values });
  };
  const newGroups = groups.map(addUniqueFacetIdToGroup);
  return newGroups;

}
