import React from "react";
import classnames from "classnames";
import {isLastPage} from "../paging";
import {openFilters} from "../filterSlider";
import ResultItem from "./ResultItem.jsx";
import LoadMore from "./LoadMore.jsx";
import LoadingSpinner from "./LoadingSpinner.jsx";
import SortBar from "./SortBar.jsx";
import SearchStatus from "./SearchStatus.jsx";
import Pagination from "./Pagination.jsx";
import {RESULTS_DISPLAY_REFRESH, RESULTS_DISPLAY_APPEND} from "../redux/resultsDisplay";

const ResultList = ({
  results,
  resultsDisplayStrategy,
  selectedFacets,
  totalResults,
  eventSpan,
  loading,
  isFirstLoad,
  sorters,
  nextPage,
  gotoPage,
  dismissFacet,
  changeSort,
  query,
  pagination,
  dictionary,
  showMobileFacets,
  removeEventSpan,
  removeEventDate
}) => {

  const isLast = isLastPage(totalResults, query.perPage, query.page);
  const shouldShowNoResults = results.length === 0 && !isFirstLoad;
  const shouldShowPager = totalResults > query.perPage;

  function showFilters() {
    openFilters(); // DOM ops
    showMobileFacets(); // action creator
  }

  function wrapperClass() {
    const isRefreshing = loading && resultsDisplayStrategy === RESULTS_DISPLAY_REFRESH;
    const isAppending = loading && resultsDisplayStrategy === RESULTS_DISPLAY_APPEND;
    return classnames("search-results", {
      "is-refreshing": isRefreshing,
      "is-appending": isAppending
    });
  }

  function resultsRange() {
    // Upper bound of visible results
    let ceiling = (query.page * query.perPage);

    // Lower bound of visible results
    let floor = (ceiling - query.perPage) + 1;

    // make sure the ceiling does not exceed total results
    if (ceiling > totalResults) {
      ceiling = totalResults;
    }

    // if results are only one page, eschew the range entirely
    if (totalResults <= query.perPage) {
      return `${totalResults}`;
    }

    // if we're using load more, we want to show only
    // the ceiling of results displayed
    if (resultsDisplayStrategy === RESULTS_DISPLAY_APPEND) {
      return `${ceiling}`;
    }

    // otherwise, return the full range
    return `${floor} - ${ceiling}`;
  }

  function renderPagingControl(n) {
    if (pagination) {
      return (<Pagination totalResults={totalResults} resultsPerPage={query.perPage} currentPage={query.page} handlePageChange={gotoPage}/>);
    }

    if (!pagination && !isLast && n == 2) {
      return <LoadMore handleClick={nextPage} dictionary={dictionary} loading={loading}/>;
    }
  }

  function renderFilterToggle() {
    return (<button type="button" className="search-results__filter-open btn--primary" onClick={() => showFilters()}>
      {dictionary.facetsTitle}
      <svg className="icon">
        <use xlinkHref="/assets/ACHE/img/svg-sprite.svg#equalizer"></use>
      </svg>
    </button>);
  }
  return (<section className={wrapperClass()}>
    {renderFilterToggle()}
    <SearchStatus loading={loading} eventSpan={eventSpan} resultsRange={resultsRange()} totalResults={totalResults} keywords={query.q} selectedFacets={selectedFacets} removeEventSpan={removeEventSpan} removeEventDate={removeEventDate} handleFacetDismiss={dismissFacet}/>
    <SortBar sorters={sorters} handleSortClick={changeSort}/> {
      shouldShowPager
        ? renderPagingControl(1)
        : null
    }

    <div className="search-results__item-wrapper">
      {
        loading && (<div className="search-results__loader">
          <LoadingSpinner/>
        </div>)
      }
      <div className="search-results__items">
        {
          shouldShowNoResults
            ? (<p>No results found to match your query.</p>)
            : null
        }
        {results.map((item) => <ResultItem readMore={dictionary.readMoreLabel} key={item.id} {...item}/>)}
      </div>
    </div>
    <div className="search-results__pagination__container">
      {
        shouldShowPager
          ? renderPagingControl(2)
          : null
      }</div>
  </section>);
};

const {
  arrayOf,
  string,
  object,
  func,
  bool,
  number,
  shape,
  oneOfType
} = React.PropTypes;

ResultList.propTypes = {
  results: arrayOf(shape({
    id: oneOfType([number, string])
  })),
  resultsDisplayStrategy: number,
  selectedFacets: arrayOf(object).isRequired,
  totalResults: number.isRequired,
  sorters: arrayOf(shape({
    id: oneOfType([number, string]).isRequired,
    label: string.isRequired,
    value: string.isRequired,
    direction: oneOfType([bool, string]),
    isActive: bool.isRequired
  })),
  loading: bool,
  isFirstLoad: bool,
  nextPage: func,
  gotoPage: func,
  changeSort: func,
  showMobileFacets: func,
  dismissFacet: func,
  query: shape({q: string, sortBy: string, page: number, perPage: number, pageId: string}),
  pagination: bool,
  dictionary: object
};

ResultList.defaultProps = {
  selectedFacets: []
};

export default ResultList;
